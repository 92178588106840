
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as throttle from "lodash/throttle"
import * as classNames from "classnames"
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { toggleSpotImPopUp } from "../../../article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { openWhatsAppShare, analyticsPushFunc } from "../../../../widgetsUtils";
import { FontSlider } from "../../../ynet1280/ynetHeader/components/mutamComponents/fontSliderComponent";
import { MainNavMutam } from "../../../ynet1280/ynetHeader/components/mutamComponents/mainNavMutam";
import { HeaderScrolledMenu } from "./headerScrolledMenu";

interface MenuState {
    isOpened: boolean
    isSticky: boolean
    isFontMenuOpen: boolean

}
interface HeaderProps {
    mainNavItems: HamburgerMenuItems[]
    groupsWithItems: Group[]
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
    radioLink:string 
    radioLinkTarget:LinkTargetProperties
    logoData: Logo
    ynetSearchPageLink: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    linkToInst:string
    isArticle: boolean
    layout?: string
    domain: string
    followUsText:string
}
export interface Group {
    groupId: string
    groupName: string
    items: HamburgerMenuItems[]
}
export interface Logo {
    url: string
    mediaTitle: string
    link: string
    linkTarget: LinkTargetProperties
}


export class SiteMutamRadionasHeaderComponenta extends React.Component<HeaderProps, MenuState> {
    public static siteScriptName = "SiteMutamRadionasHeaderComponenta"
    constructor(props) {
        super(props)
        this.state = {
            isOpened: false,
            isSticky: false,
            isFontMenuOpen: false
        }
    }
    private openMenu = () => {
        this.setState({ isOpened: !this.state.isOpened })
        document.body.classList.toggle('lock-scroll');
    }
    //

    private stickyBar() {
        const headerPos = document.querySelector(".radionas-header").getBoundingClientRect().top
        if (headerPos + 40 < 0) {
            this.setState({ isSticky: true })

        } else {
            this.setState({ isSticky: false })
        }

        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        let element = document.getElementById('ArticleBodyComponent');
        if (element) {
            let headerElRect = document.querySelector(".ArticleHeaderMobileComponent") && document.querySelector(".ArticleHeaderMobileComponent").getBoundingClientRect();
            let scrolled = (winScroll / (element.offsetTop + element.offsetHeight - headerElRect.height)) * 100;
            if (scrolled < 100) {
                document.getElementById("progress-bar").style.width = scrolled + "%";

            } else {
                document.getElementById("progress-bar").style.width = "100%";
            }
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler())
        this.props.isArticle && this.setfontSize();
    }
    private setfontSize = () => {
        if (localStorage && localStorage.fontSize) {
            let size = localStorage.fontSize;
            document.documentElement.style.fontSize = size * parseInt(getComputedStyle(document.documentElement).fontSize) + "px";
        }

    }



    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler())
    }
    private throttledScrollHandler = () => throttle(() => this.stickyBar(), 200)

    private openShare = async () => {
        const { domain } = this.props;
        const current_url = `${window.location.href}?utm_source=${domain}&utm_medium=social&utm_campaign=general_share`;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }
    private handleCommentsPopupVisability = () => {
        if (typeof window !== "undefined") {
            if (window.enableSpotIm) {
                const commentsPopUp = document.querySelector('.spotimWrapper');
                if (commentsPopUp) {
                    toggleSpotImPopUp(commentsPopUp)
                }
            }
            else {
                window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
            }
        }
    }



    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    
    render() {
        const { mailLinkTarget, flashPageLink, flashLinkTarget,radioLinkTarget,followUsText,   mainNavItems, groupsWithItems,linkToInst, logoData, ynetSearchPageLink, linkToFb, linkToTw, linkToYtube, isArticle, redMailLink, layout, domain,radioLink } = this.props
        const { isSticky, isFontMenuOpen } = this.state
        const numOfComment = typeof window !== "undefined" && window.handleCommentsPopupVisability && window.wcmNumOfComments && window.wcmNumOfComments;
        const hidden = isSticky ? "hidden" : ""
        const tooltipHidden = !isSticky ? "hidden" : "";
        const url = typeof window !== "undefined" && isArticle && window.location.href;
        const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
        

            return (
                <div className={classNames("RadionasMutamHeader")} id="RadionasMutamHeader" >

                    <div className={`logo ${hidden}`}>
                        <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle}>
                        <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                        </HyperLinkBuilder>

                    </div>


                    <MainNavMutam
                        mainNavItems={mainNavItems}
                        openHam={this.openMenu}
                    />


                    <div className={`bottomHeaderArea ${hidden}`} >
                    <div className="rightSideLinks">
                    <HyperLinkBuilder href={redMailLink} linkTarget={mailLinkTarget} >
                            <span className="headerIcon redMail" /> <span className="redMailText">البريد الأحمر</span>
                        </HyperLinkBuilder> 
                          <HyperLinkBuilder href={flashPageLink} linkTarget={flashLinkTarget} >
                            <span className="headerIcon flash" id="flashBell" /> <span className="flashText">فلاش المراسل</span>
                        </HyperLinkBuilder>
                        </div>
                        <HyperLinkBuilder href={radioLink} linkTarget={radioLinkTarget}  className="leftSideRadioLink">
                            <span className="radioIcon"/> <span className="radioText">راديو مباشر</span>
                        </HyperLinkBuilder>
    
                    </div>
                    <HeaderScrolledMenu
                        groupsWithItems={groupsWithItems}
                        isVisible={this.state.isOpened}
                        onClose={this.openMenu}
                        ynetSearchPageLink={ynetSearchPageLink}
                        linkToFb={linkToFb}
                        linkToTw={linkToTw}
                        linkToYtube={linkToYtube}  
                        layout={layout}
                        domain={domain}
                        flashPageLink={flashPageLink}
                        flashLinkTarget={flashLinkTarget}
                        redMailLink={redMailLink}
                        mailLinkTarget={mailLinkTarget}
                        linkToInst={linkToInst}
                        followUsText={followUsText}
                        />
                </div>

            )

    }
}