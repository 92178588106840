import { StoriesEntity } from "./storiesAutoComponenta";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";

interface StoriesAutoMobileSlotItemProps {
    entity: StoriesEntity
    index:number
    onStoriesOpen: (clickedIndex:number) => void
}

export class StoriesAutoMobileSlotItem extends React.Component<StoriesAutoMobileSlotItemProps, {}> {

    public render() {
        const { entity: { promotionName, media, linkTarget, link }, onStoriesOpen, index } = this.props;

        return (
            <div className="slotView" onClick={() =>onStoriesOpen(index)}>
                <div className="MediaCarousel">
                    <SiteMedia
                        data={media}
                        width={100}
                        height={100}
                        videoSettings={null}
                        itemLinkUrl={link.link.publishedLink}
                        isImageLinkable={true}
                        linkTarget={linkTarget}
                    />
                </div>
                <div className="contentDiv">
                    <div className="slotTitle">{promotionName}</div>
                </div>
            </div >
        )
    }
}