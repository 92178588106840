import { IFieldDisplayData } from "../../../../commonDataObjects/interfaces";
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { getTranslation } from "../../autoTickerRowWidget/autoTickerRowTranslations";
import { minutesDiff } from "./newsFlashAndTwentyFourSevenMobileComponenta";

interface TickerSlotItemProps {
    article: ArticleExtraData
    tickerFieldsDisplaySettings: IFieldDisplayData
    isFirstInterval: boolean
    languageCode: string
    highlightingDuration: number
}

interface TickerSlotItemState {
    isExpandOpen: boolean
    shouldDisplayArticle: boolean
    isFirstRender: boolean
}

export class TickerSlotItem extends React.Component<TickerSlotItemProps, TickerSlotItemState>{
    constructor(props) {
        super(props);
        this.state = {
            isExpandOpen: false,
            shouldDisplayArticle: true,
            isFirstRender: true
        }
    }

    private onSlotClickHandler = (e: React.MouseEvent) => {
        const { title } = this.props.article;
        const { isExpandOpen } = this.state;
        if ((e.target as HTMLButtonElement).id !== "ticker-button-share") {
            this.dispatchCustomEvent(title);
            this.setState({ isExpandOpen: !isExpandOpen });
        } else {
            this.openShare();
        }
    }

    private dispatchCustomEvent = (title: string) => {
        const { isExpandOpen } = this.state;
        document.dispatchEvent(
            new CustomEvent("newsFlashesTwentyFourSevenReadMoreChange",
                {
                    detail: {
                        action: isExpandOpen ? "Collapse" : "Expand",
                        label: title,
                    }
                }
            ))
    }

    private dispatchShareCustomEvent = (title: string) => {
        document.dispatchEvent(
            new CustomEvent("newsFlashesTwentyFourSevenShare",
                {
                    detail: {
                        label: title,
                    }
                })
        )
    }

    private openShare = async () => {
        const { article: { title, publishedLink } } = this.props;
        const shareData = { url: publishedLink, title: title };
        try {
            // @ts-ignore
            navigator.share(shareData);
            this.dispatchShareCustomEvent(title);
        } catch (error) {
            console.log("error with sharing function", error)
        }
    }

    componentDidMount() {
        if (this.state.isFirstRender) {
            this.firstRender();
            this.setState({ isFirstRender: false })
        }
    }

    private firstRender = () => {
        const { isFirstInterval, article, highlightingDuration } = this.props;
        const isNow = minutesDiff(article) === 0;
        const isUpToFiveMin = minutesDiff(article) < highlightingDuration;
        if (isFirstInterval) {
            isUpToFiveMin && this.setState({ shouldDisplayArticle: false });
            setTimeout(() => {
                this.setState({ shouldDisplayArticle: true })
            }, 3000)
        } else {
            isNow && this.setState({ shouldDisplayArticle: false });
            setTimeout(() => {
                this.setState({ shouldDisplayArticle: true });
            }, 3000)
        }
    }

    public render() {
        const { article, languageCode, highlightingDuration } = this.props;
        const { title, dateUpdatedOnSite, plainText, roof, author } = article;
        const { isExpandOpen, shouldDisplayArticle } = this.state;
        const newItem = minutesDiff(article) < highlightingDuration;
        const isPublishedNow = newItem ? "now" : "";
        const isOpen = isExpandOpen ? "open" : "";
        const lang = languageCode ? languageCode.slice(0, 2) : "he";
        const date = newItem ? getTranslation(lang, "Now") : <DateDisplay date={dateUpdatedOnSite} isMinutesAgo={true} isArticle={true} />;
        const isAuthor = author && author.length > 0;
       
        return (
            <>
                {shouldDisplayArticle &&
                    <div className={`slotView ${isOpen} ${isPublishedNow}`} onClick={this.onSlotClickHandler}>
                        {roof && <div className="roofTitle">{roof}</div>}
                        <div className="textDiv">
                            <div className="slotTitle">
                                {title}
                            </div>
                            <div className={`slotText ${isOpen ? "show" : ""}`}>
                                {plainText}
                                {isAuthor && <br/>}
                                {isAuthor && `(${author})`}
                            </div>
                        </div>
                        <div className="moreDetails" >
                            <div className="dateView">{date}</div>
                            <div className="buttonsWrapper">
                                <div className="buttonShare" id="ticker-button-share" />
                                <div className="buttonExpand" />
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}