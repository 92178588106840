import { ReactInstaStories } from "../../../react-insta-stories/reactInstaStories";
import { Story } from "../../../react-insta-stories/interfaces";
import { StoriesEntity } from "./storiesAutoComponenta";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";

interface StoriesItemProps {
    entity: StoriesEntity
    activeEntityIndex: number
    index: number
    onStorySelect: (index: number) => void
    onStoryNext: () => void
    onStoryPrev: () => void
}

interface StoriesItemState {
    stories: Story[]
    currentIndex: number
}

export class StoriesItem extends React.Component<StoriesItemProps, StoriesItemState> {
    constructor(props) {
        super(props);
        this.state = {
            stories: null,
            currentIndex: 0,
        }
    }

    componentDidMount() {
        const { entity: { slotId } } = this.props;
        this.getStories();
        this.getStoryCurrentIndex(slotId);
    }

    public getStories() {
        const { entity } = this.props;
        const storiesArray = entity.linkedItems.map((linkedItem) => {
            const isVideoStory = linkedItem.hasVideo;
            const url = isVideoStory && linkedItem.video ? linkedItem.video[0].high_res_link : "https://vod-progressive-dev.ynethd.com/0324/1903_test_player_9_16_720p.mp4";
            return { url, type: 'video', }
        });
        this.setState({ stories: storiesArray });
    }

    public setStoryCurrentIndex = (slotId: string, index: number) => {
        localStorage.setItem(slotId, index.toString())
    }

    public getStoryCurrentIndex = (slotId: string) => {
        const currentIndex = localStorage.getItem(slotId);
        this.setState({ currentIndex: +currentIndex });
    }

    public onNextStory = () => {
        const { currentIndex, stories } = this.state;
        const { entity: { slotId }, onStoryNext } = this.props;
        if (currentIndex === stories.length - 1) return onStoryNext();

        this.setState({ currentIndex: this.state.currentIndex + 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
        // console.log("onNextStory")
    }

    public onPrevStory = () => {
        const { currentIndex } = this.state;
        const { entity: { slotId } } = this.props;
        if (currentIndex === 0) return;
        this.setState({ currentIndex: this.state.currentIndex - 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
        // console.log("onPrevStory")
    }

    public onStoryStart = () => {
        console.log("story startes")
    }

    public render() {
        const { stories, currentIndex } = this.state;
        const { entity: { promotionName, media, linkTarget, link }, index, activeEntityIndex, onStorySelect } = this.props;
        const isMainStory = index === activeEntityIndex;
        // console.log("entity:", promotionName,index);

        return (
            <div className="slotView">
                {stories &&
                    <div className="storyItems">
                        {/* {isMainStory &&
                            <div className="mainStory">
                                <ReactInstaStories
                                    stories={stories}
                                    defaultInterval={20000}
                                    width={400}
                                    height={709}
                                    onNext={this.onNextStory}
                                    onPrevious={this.onPrevStory}
                                    onAllStoriesEnd={() => { }}
                                    currentIndex={currentIndex}
                                    onStoryStart={this.onStoryStart}
                                    preventDefault={true}
                                />
                                <div className="moreDetails" >
                                    <div className="shareBtn"></div>
                                    <div className="mediaArea">
                                        <div className="slotTitle">{promotionName}</div>
                                        <div className="MediaCarousel">
                                            <SiteMedia
                                                data={media}
                                                width={50}
                                                height={50}
                                                videoSettings={null}
                                                itemLinkUrl={link.link.publishedLink}
                                                isImageLinkable={true}
                                                linkTarget={linkTarget}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } */}
                        <div className="storyPlaceholder" onClick={() => onStorySelect(index)}>
                            <div className="MediaCarousel">
                                <SiteMedia
                                    data={media}
                                    width={100}
                                    height={100}
                                    videoSettings={null}
                                    itemLinkUrl={link.link.publishedLink}
                                    isImageLinkable={true}
                                    linkTarget={linkTarget}
                                />
                            </div>
                            <div className="slotTitle">{promotionName}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}