interface YnetLiveFloatingPlayerProps {
    isMobileWeb: boolean
    isAudioPlaying: boolean
    isFloatingPlayerVisible: boolean
    floatingPlayerBottomHeight: number
    onClose: () => void
    children?: any
    title: string
    onTogglePlayPause: () => void
}

export const YnetLiveFloatingPlayer = (props: YnetLiveFloatingPlayerProps) => {
    const { isAudioPlaying, isFloatingPlayerVisible, isMobileWeb, onClose, title, onTogglePlayPause, floatingPlayerBottomHeight} = props;
    const mainTitle = isMobileWeb ? "האזינו לשידור" : "עכשיו בשידור";
    const progressBar = props.children;
    const mobileBanner = document.getElementById("ads.banner");
    const mobileStyle = mobileBanner ? mobileBanner.clientHeight : 65;

    return (
        <div className={`ynet-live-floating-player ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{bottom: `${isMobileWeb ? mobileStyle: floatingPlayerBottomHeight}px`}}>

            <div className="floating-player-content">
                <div className="floating-player-show-details">
                    <button className="floating-player-button" onClick={onTogglePlayPause} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                        <div className={`floating-player-button-status ${isAudioPlaying ? "pause" : "play"}`} ></div>
                    </button>
                    {progressBar}
                    <img className="floating-player-equalizer-gif" src={"https://www.ynet.co.il/images/equalizer.gif"} />
                </div>
                <div className="floating-player-show-title-wrapper">
                    <div className="floating-player-show-main-title">{mainTitle}</div>
                    <div className="separator"></div>
                    <div className="floating-player-show-title">{title || 'אין תוכנית לנגן'}</div>
                </div>
            </div>
            <button className="floating-player-close-sign" onClick={onClose} aria-label="close player"></button>
        </div>
    )
}