import * as axios from "axios";
import { CategoryListItem } from "./videoArchiveComponenta";
import { VideoArchiveArticleList } from "./videoArchiveArticleList";
import { VideoArchivehDatePickerMenu } from "./videoArchiveDatePickerMenu";
import { VideoArchiveChannelMenu } from "./videoArchiveChannelMenu";
import VideoArchiveMainItem from "./videoArchiveMainItem";
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils";
import { StandardVideoSlotItemMutam } from "../../generalMutamComps/standardVideoSlotItemMutam";


interface VideoArchiveState {
    currChannel: CategoryListItem
    isChannelMenuVisible: boolean
    isDateMenuVisible: boolean
    currPage: number
    limitDate: Date
    articles: VideoArchiveArticle[]
    isLoadMore: boolean
    isLoading: boolean
    playingVideoElement: HTMLElement
}
interface VideoArchiveProps {
    categoryList: CategoryListItem[]
    numberOfItems: number
    isLTR: boolean
    componentaId: string
    isMobileWeb?: boolean
}

export interface VideoArchiveArticle {
    articleId: string
    author: string
    publishedLink: string
    category: any
    hasVideo: boolean
    video: any
    title: string
    dateUpdatedOnSite: Date
    subTitle: string
    promotionImageDetails: PromotionImageDetails
}
interface PromotionImageDetails {
    publishedLink: string
}

const wordsTranslations = {
    "he": {
        "Load more": "טען עוד",
        "No results": "אין תוצאות"
    },
    "en": {
        "Load more": "Load more",
        "No results": "No results"
    }

}
export class SiteVideoArchiveComponenta extends React.Component<VideoArchiveProps, VideoArchiveState> {
    public static siteScriptName = "SiteVideoArchiveComponenta"
    constructor(props) {
        super(props)
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            currChannel: { description: '', id: '1' },
            isChannelMenuVisible: false,
            isDateMenuVisible: false,
            currPage: 0,
            limitDate: new Date(),
            articles: [],
            isLoadMore: false,
            isLoading: false,
            playingVideoElement: null,
        }
    }

    componentDidMount() {
        this.setState({ currChannel: { description: 'כל הערוצים', id: '1' } })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.limitDate !== prevState.limitDate ||
            this.state.currPage !== prevState.currPage ||
            this.state.currChannel !== prevState.currChannel) {
            this.setState({ isLoading: true })
            this.getArticles();
        }
        if (this.state.articles !== prevState.articles) {
            this.setState({
                isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfItems) >= 1
            })
        }
    }

    private updateNewPlayingVideoElement = (newVideoElement: HTMLElement) => {
        this.setState({ playingVideoElement: newVideoElement })
    }

    private onChannelChanged = (newChannel) => {
        this.toggleChannelMenu()
        this.setState({
            currChannel: newChannel,
            currPage: 0
        })
    }

    private toggleChannelMenu = () => {
        this.setState({
            isChannelMenuVisible: !this.state.isChannelMenuVisible,
            isDateMenuVisible: (this.state.isDateMenuVisible && !this.state.isChannelMenuVisible) ? false : this.state.isDateMenuVisible
        })
    }

    private toggleDateMenu = () => {
        this.setState({
            isDateMenuVisible: !this.state.isDateMenuVisible,
            isChannelMenuVisible: (this.state.isChannelMenuVisible && !this.state.isDateMenuVisible) ? false : this.state.isChannelMenuVisible
        })

    }

    private handleDayClick = (day, modifiers = {}) => {
        if (modifiers['disabled']) {
            return;
        } else {
            this.setState({
                limitDate: day ? day : '',
                currPage: 0
            });
            this.toggleDateMenu();
        }
    }

    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }

    private getArticles = () => {
        const { componentaId } = this.props
        const { currChannel, currPage, limitDate, articles } = this.state
        const formatedStartDate = formatDate(new Date('1992-04-01'), "YYYY-MM-DD");
        const date = formatDate(limitDate, "YYYY-MM-DD");
        axios.get(`/iphone/json/api/article_list/${componentaId}/id/${currChannel.id}/startDate/${formatedStartDate}/endDate/${date}/pageNumber/${currPage}`)
            .then((res: any) => {
                const recivedArticles = res.data.data;
                this.setState({
                    articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles,
                    isLoading: false
                })
            }).catch(err => console.error("error geting articles data:", err))
    }

    private getTranslation = (lang, text: string) => {
        let obj = wordsTranslations[lang]
        let textToShow = obj[text]
        return textToShow
    }

    private getFormattedDate = (dateUpdated: Date) => {
        const date = new Date(dateUpdated);
        let dd = "" + date.getDate();
        let mm = "" + (date.getMonth() + 1);
        const yy = date.getFullYear().toString().slice(2, 4);

        if (mm.length < 2) mm = "0" + mm;
        if (dd.length < 2) dd = "0" + dd;

        return [dd, mm, yy].join(".");
    }

    render() {
        const { categoryList, isLTR, isMobileWeb } = this.props
        const { currChannel, isChannelMenuVisible, limitDate, articles, isDateMenuVisible, isLoadMore, isLoading } = this.state
        const isFirstVideoExist = articles.length > 0 && articles[0].video;
        const loadingMoreBtnClass = `loadMoreButton ${isLoading ? "loading" : ""}`;
        const dayPickerPreferences = {
            showOutsideDays: true,
            month: new Date(),
            toMonth: new Date(),
            fixedWeeks: true,
            disabledDays: [{ after: new Date() }]
        }

        return (
            <div className="videoArchiveComponenta">
                {isMobileWeb ?
                    articles.length > 0 ?
                        articles.map((article, i) =>
                            <StandardVideoSlotItemMutam
                                article={article}
                                key={article.articleId}
                                playingVideoElement={this.state.playingVideoElement}
                                updateNewPlayingVideoElement={this.updateNewPlayingVideoElement}
                                fieldsDisplaySettings={{ isDateVisible: true, isAuthorVisible: true }}
                            />
                        )
                        :
                        <div className="noResultsMsg" style={{ width: "fit-content", margin: "0 auto", padding: 20 }}>
                            {this.getTranslation(isLTR ? "en" : "he", "No results")}
                        </div>

                    :
                    <>
                        {isFirstVideoExist && <VideoArchiveMainItem mainArticle={articles[0]} getFormattedDate={this.getFormattedDate} />}

                        <div className="separator" />

                        <div className="filters">
                            <VideoArchiveChannelMenu
                                isLTR={isLTR}
                                currChannel={currChannel}
                                categoryList={categoryList}
                                onChannelChanged={this.onChannelChanged}
                                toggleChannelMenu={this.toggleChannelMenu}
                                isChannelMenuVisible={isChannelMenuVisible}
                            />

                            <VideoArchivehDatePickerMenu
                                isLTR={isLTR}
                                limitDate={limitDate}
                                toggleDateMenu={this.toggleDateMenu}
                                handleDayClick={this.handleDayClick}
                                isDateMenuVisible={isDateMenuVisible}
                                dayPickerPreferences={dayPickerPreferences}
                            />
                        </div>

                        <div className="bottomSeparator" />

                        <div className="videoMain">
                            <div className="mainTitle">כל הסרטים</div>

                            {(articles.length > 0) ?
                                < VideoArchiveArticleList
                                    isLTR={isLTR}
                                    itemList={articles}
                                    currChannel={currChannel}
                                    getFormattedDate={this.getFormattedDate}
                                />
                                :
                                <div className="noResultsMsg">{this.getTranslation(isLTR ? "en" : "he", "No results")}</div>
                            }

                            <div className="loadMore">
                                <button className={loadingMoreBtnClass} disabled={!isLoadMore || articles.length === 0} onClick={this.loadMoreArticles}>{this.getTranslation(isLTR ? "en" : "he", "Load more")}</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}