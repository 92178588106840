import Slider from 'react-slick'
import { hotArticleSlotItemData } from './hotArticleComponentaMutam';
import { HyperLinkBuilder } from '../../../../commonComponents/inline/hyperLink';

interface HotArticleSlotListMutamProps {
    slots: hotArticleSlotItemData[]
}

interface HotArticleSlotListMutamState {
    activeSlide: number
}

export class HotArticleSlotListMutam extends React.Component<HotArticleSlotListMutamProps, HotArticleSlotListMutamState>{
    public static siteScriptName = "hotArticleComponentaMutam";
    constructor(props) {
        super(props);
        this.state = {
            activeSlide: 0,
        }
    }

    private slider;

    public render() {
        const { slots } = this.props;
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };

        return (
            <div className="slotList">
                <Slider ref={slider => (this.slider = slider)} {...settings} className="slider"  >
                    {slots.map((slotItem, index) =>{
                       const  imageUrl = slotItem.imageSrc && slotItem.imageSrc.url.length>0 ? slotItem.imageSrc.url : "";
                        return(
                            <div className="slotView" key={index}>
                            <div className="imageArea">
                                <HyperLinkBuilder href={slotItem.link} linkTarget={slotItem.linkTarget} title={slotItem.item.title}>
                                    <img src={imageUrl} alt={slotItem.item.title}/>
                                    <div className="imageOverlay">
                                        <div className="overlayText">{slotItem.item.title}</div>
                                    </div>
                                </HyperLinkBuilder>
                            </div>
                        </div>
                        )
                    }
                      
                    )}
                </Slider>
            </div>
        )
    }
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickPrev"
            onClick={onClick}
        />
    );
}

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickNext"
            onClick={onClick}
        />
    );
}