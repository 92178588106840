import * as axios from "axios"
import * as filter from "lodash/filter"
import { ArticleExtraData } from "articlePage/stateInterfaces"
import { CalcalistArticleHeadlinesView } from "./articleHeadlinesMoreView";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { Loader } from "../../../yPlusLoadMore/components/loader/loader";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { removeByIndex } from "../../../../../utils/immutableHelpers/array";



interface ArticleHeadlinesProps {
    categoryId: string
    componentaId?: string
    firstPageArticles?: ArticleExtraData[]
    numberOfVisibleItems: number
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    isLoadMoreVisible: boolean
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    tabText: string
    tabColor: string
    isTaboolaVisible: boolean
    isLTR?: boolean
    includeVideo: boolean
    hideVideoAds?: boolean
    commercialRepetitionsNum:number
    commercialItemsNum:number
    isMutam: boolean
}

interface ArticleHeadllinesState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export class SiteArticleHeadlinesComponenta extends React.Component<ArticleHeadlinesProps, ArticleHeadllinesState> {
    public static siteScriptName = "SiteArticleHeadlinesComponenta"
    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    componentDidUpdate(prevProps: ArticleHeadlinesProps) {
        if (this.props.firstPageArticles !== prevProps.firstPageArticles) {
            this.setState(
                {
                    articles: this.props.firstPageArticles
                }
            );
        }
    }

    componentDidMount() {
        const { categoryId } = this.props;
        if (categoryId) {
            this.getArticles()
        }
    }

    private componentUId = Math.floor(Math.random() * 10000)

    private getArticles = () => {
        const { componentaId, } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState(
            {
                articles: articles,
                showLoader: true,
                showButton: false
            }
        );


        axiosInstance.get(`/iphone/json/api/calcalist_auto_articles/${componentaId}/${pageNumber}`)
            .then((res: any) => {
                const recivedArticles = res.data.data as ArticleExtraData[];
                this.setState(
                    {
                        articles: [...articles, ...recivedArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                        showButton: recivedArticles && recivedArticles.length === this.props.numberOfVisibleItems,
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err))
    }



    public render() {
        const { isLoadMoreVisible, fieldsDisplaySettings, tabLink, tabLinkTarget, tabText, tabColor, isLTR, includeVideo, hideVideoAds } = this.props
        const { articles, showLoader: loader, showButton } = this.state;
        return (
            <div className={"CalcalistArticleHeadlinesComponenta"}>
                {fieldsDisplaySettings.isTabVisible && (tabText.length > 0) ? <div className="TabComponenta" >
                    <HyperLinkBuilder
                        className="titleLink mainTitleLink"
                        href={tabLink}
                        linkTarget={tabLinkTarget}
                        style={{ color: tabColor }}><div className="mainTitleText" >{tabText}</div></HyperLinkBuilder>
                </div> : null
                }
                <CalcalistArticleHeadlinesView
                    loader={loader}
                    articles={articles}
                    {...this.props}
                    uid={`${this.componentUId}`}
                    includeVideo={includeVideo}
                    hideVideoAds={hideVideoAds}
                />
                <div className="ButtonAndLoaderContainer">
                    {loader && <Loader />}
                    {isLoadMoreVisible && showButton && <div className={"loadMoreButton"} onClick={() => this.getArticles()} >{isLTR ? "Load More" : "טען עוד "}</div>}
                </div>
            </div >
        )
    }
}