require('./site_SiteScrollToTopButton.less')
import * as throttle from "lodash/throttle"
interface SiteScrollToTopButtonState {
    isVisible: boolean
}
export class SiteScrollToTopButton extends React.Component<{}, SiteScrollToTopButtonState>{
    public static siteScriptName = "SiteScrollToTopButton"
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false
        }
    }


    public componentDidMount() {
        document.addEventListener("scroll", this.throttledScrollHandler)
    }

    private handleOnScroll = () => {
        if (typeof window !== 'undefined' && window["siteID"] === "ynet") {
            if (document.body.scrollTop > 900 || document.documentElement.scrollTop > 900) {
                this.setState({ isVisible: true })
            }
            else {
                this.setState({ isVisible: false })
            }
        } else {
            if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
                this.setState({ isVisible: true })
            }
            else {
                this.setState({ isVisible: false })
            }
        }
    }
    private throttledScrollHandler = throttle(this.handleOnScroll, 250)

    private scrollToTop = (element, duration) => {
        if (duration < 0) return;
        var difference = 0 - element.scrollTop;
        var perTick = difference / duration * 2;

        setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            this.scrollToTop(element, duration - 2);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.throttledScrollHandler)
    }

    render() {
        const { isVisible } = this.state
        if (!isVisible) { return null }
        return <button className="SiteScrollToTopButton no-print"
            title="חזרה לראש הדף"
            onClick={e => this.scrollToTop(document.getElementsByTagName("html")[0], 35)} />
    }
}