import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { isHavakVisible, PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { MediaContentTypes } from "constants/contentTypes"
import { MarketingSign } from "../../../../commonComponents/inline/marketingSign/marketingSign"
import { PodcastPlayer } from "../../../radioWidgets/floatingPlayer/podcastPlayerButton";
import { secondsToHms } from "../../../../siteWidgets/siteWidgetUtils";



interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    margin?: number
    onRowDisplay: boolean
    titleColor: string
    isYplusComp?: boolean
    isSmallItem?: boolean
    isLTR: boolean
    isImageOnly: boolean
    includeAudioPlayer: boolean
}



export class MultiImagesItemFront extends React.Component<MultiImagesFrontProps>{

    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }

    private getSlotViewStyle = () => {
        const { onRowDisplay, isLTR, margin, isImageOnly } = this.props;
        let marginStyle = { margin: "0 10px 24px" };
        if (onRowDisplay) {
            if (!isLTR) {
                marginStyle = { margin: "unset", marginLeft: margin }
            }
            else {
                marginStyle = { margin: "unset", marginRight: margin }
            }
        }
        if (isImageOnly) {
            marginStyle = { margin: "0 10px 10px" }
        }


        return marginStyle;
    }

    public render() {
        const { imageWidth, item, imageHeight, videoSettings, margin, fieldsDisplaySettings, onRowDisplay, titleColor, isYplusComp, isSmallItem, isImageOnly, includeAudioPlayer } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const slotViewStyle = this.getSlotViewStyle();
        const titleStyle = {
            color: titleColor
        }
        const icon = item.icon ? item.icon : "automatic";
        const showUpInfoArea = fieldsDisplaySettings.isChannelNameVisible
        const shouldDisplaySeparator = fieldsDisplaySettings.isAuthorVisible && item.itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible && item.author.length > 0;
        const shouldDisplayDate = fieldsDisplaySettings.isDateVisible && item.itemType === LinkedItemType.ARTICLE;
        const isPlus = isHavakVisible({ premiumExpireDays: item.premiumExpireDays, dateUpdatedOnSite: item.dateUpdatedOnSite });
        const isAudioIconVisible = includeAudioPlayer && (item.icon === "audio" || item.icon === "automatic") && item.audioArticleData && item.audioArticleData.audioUrl && item.audioArticleData.durationSeconds;
        const itemLabel = `${item.categoryName} - ${item.title}`;
        const browserDisplayMediaMetaData = { title: item.title, artist: "ynet", album: item.categoryName }
        return (<div className="slotView" style={slotViewStyle}>

            <div className="imageArea" data-tb-thumbnail=''>

                <PayWallArctilceIcon
                    premiumExpireDays={item.premiumExpireDays}
                    dateUpdatedOnSite={item.dateUpdatedOnSite}
                    className={`onImage`}
                    dataTbPremium />
                <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }} >
                    <SiteMedia
                        data={item.media !== undefined ? item.media : emptyMedia}
                        width={imageWidth}
                        height={imageHeight}
                        videoSettings={videoSettings}
                        itemLinkUrl={item.titleLink}
                        isImageLinkable={!isAudioIconVisible}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                    />
                 
                    <SlotIconDisplay
                        itemId={item.itemId} icon={isAudioIconVisible ? "audio" : this.getIcon(icon, item.hasVideo)} iconClassName="medium"
                        isIconVisible={item.hasVideo || icon === "video" || icon === "automatic" || icon === "audio"}
                        duration={isAudioIconVisible && secondsToHms(item.audioArticleData.durationSeconds)}
                        durationShow={isAudioIconVisible} />
                </div>
                {!isPlus && !includeAudioPlayer &&
                    item.isMarketingContent && <MarketingSign isSmallItem={isSmallItem} marketingContentText={item.marketingContentText} />}
                {!isPlus &&
                    !item.isMarketingContent && item.redLabelText && item.redLabelText.length > 0 && <span className={`basicLabel labelText ${item.textPlace}`}>{item.redLabelText}</span>}
            </div>
            {!isImageOnly && <div className="slotTextArea">

                {showUpInfoArea && <div className="upInfoArea">
                    {fieldsDisplaySettings.isChannelNameVisible && item.subchannelName !== null && item.subchannelName}
                </div>}
                {fieldsDisplaySettings.isTitleVisible && <div className={`slotTitle medium ${item.isCommertial ? "commertialTitle" : ""}`} >
                    <HyperLinkBuilder
                        href={item.titleLink}
                        isDataTbLink={true}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        children={item.title} >
                        <div dangerouslySetInnerHTML={{ __html: item.title }} style={titleStyle} data-tb-title='' />
                    </HyperLinkBuilder>
                </div>}

                {(!item.isYplusItem || !isYplusComp) && <div className="moreDetails">
                    {fieldsDisplaySettings.isAuthorVisible && <span className="authorField" data-tb-author=''>{item.author}</span>}
                    {shouldDisplaySeparator && <span>|</span>}
                    {shouldDisplayDate && <DateDisplay date={item.dateUpdatedOnSite} isTbDate />}
                </div>}
            </div>}

            {isAudioIconVisible && <PodcastPlayer
                audioUrl={item.audioArticleData.audioUrl}
                title={itemLabel}
                className="audio-play-button"
                durationSeconds={item.audioArticleData.durationSeconds}
                browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                isMobileWeb={false}
                articleLink={item.titleLink}
                articleData={item}

            />
            }

        </div>
        )
    }
}
// <div >