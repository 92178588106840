import { MediaContentTypes } from "../../../../../constants/contentTypes"
import { SiteMedia } from "../../../../siteWidgets/siteMedia"
import { SiteMediaData, SiteImageData } from "../../../../interfaces"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SlotIconDisplay } from "../../../../commonComponents/inline/slotIconDisplay/slotIconDisplay";
import { calculateShouldAddArticleAutoplayIndicator } from "widgets/widgetsUtils"
import classNames = require("classnames")
import { convertHtmlToString } from "../../../../../config/utils";
import { ComponentaIconType } from "widgets/commonDataObjects/interfaces"
import { CalcalistMutamShareMenu } from "../../generalMutamComps/calcalistMutamShareMenu";
import { separateHourFromDateAndFormatToString } from "../../../../widgetsUtils"


interface CalcalistSpecialContentItemMobileProps {
    mediaWidth: number
    mediaHeight: number
    itemData: any
    videoSettings: LinkedItemVideoSettings
    marginLeft?: number
    titleTextColor: string
    isLTR?: boolean
}

export class CalcalistSpecialContentItemMobile extends React.Component<CalcalistSpecialContentItemMobileProps, {}>{

    render() {
        const { titleTextColor, videoSettings, itemData, mediaWidth, mediaHeight, isLTR } = this.props
        const shouldAddArticleAutoplayIndicator = calculateShouldAddArticleAutoplayIndicator(itemData.icon, itemData.media);
        const articlePublishDate = separateHourFromDateAndFormatToString(itemData.date, { showHour: false });
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        let iconType: ComponentaIconType = "none";
        if (itemData.hasVideo) {
            iconType = "video";
        }
        if (itemData.articleType === "audio") {
            iconType = "audio";
        }
        return (
            <div className={classNames("CalcalistSpecialContentItemMobile")}>
                <div className="mediaArea CalcalistMutamShareMenu">
                    <div className="MediaCarousel">
                        <SiteMedia
                            allMedias={[]}
                            data={itemData.media !== undefined ? itemData.media : emptyMedia}
                            width={mediaWidth}
                            height={mediaHeight}
                            videoSettings={videoSettings}
                            itemLinkUrl={itemData.titleLink}
                            isImageLinkable={true}
                            linkTarget={itemData.titleTargetLink !== undefined && itemData.titleTargetLink}
                            shouldAddArticleAutoplayIndicator={shouldAddArticleAutoplayIndicator}
                        />
                        <SlotIconDisplay itemId={itemData.itemId} icon={iconType} iconClassName="big" isIconVisible={iconType !== "none"} />
                    </div>
                    <CalcalistMutamShareMenu url={itemData.titleLink} isArticle={itemData.linkType === "ARTICLE"} title={convertHtmlToString(itemData.title)} />
                </div>
                <div className="titleWrapper">
                    <HyperLinkBuilder
                        href={itemData.titleLink}
                        linkTarget={itemData.tabLinkTarget !== undefined && itemData.tabLinkTarget}
                        isDataTbLink>
                        <div className="title" style={{ color: titleTextColor }} dangerouslySetInnerHTML={{ __html: itemData.title }} />

                        <div className="publishDate">
                            <span className="date">{articlePublishDate}</span>
                            <span> | </span>
                            <span> {itemData.author} </span>
                        </div>
                    </HyperLinkBuilder>
                </div>
            </div>
        )
    }
}