import { ArticleExtraData } from "articlePage/stateInterfaces"
import * as apiUrls from "config/apiUrls"
import { getImgAlt, isAriaHidden, separateHourFromDateAndFormatToString } from "widgets/widgetsUtils"
import { ImageDetails } from "images/interfaces"
interface MusafArticleItemSlotProps {
    article: Partial<ArticleExtraData>
}

export class MusafArticleItemSlot extends React.Component<MusafArticleItemSlotProps, {}>{
    public render() {
        const { article: {promotionImageDetails, dateUpdatedOnSite, publishedLink },  } = this.props;
       
        let title = `שער מוסף ${separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}`
        
        return (
            <div className="slotItem">
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                        <a href = {publishedLink} target="_blank">
                            <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 270, h: 386 })}
                                style={{ width: 270, height: 386 }}
                                alt={getImgAlt(promotionImageDetails)}
                                aria-hidden={isAriaHidden(promotionImageDetails)}
                                title={title}
                        />
                        </a>
                    </div>
                </div>
                <span className="dateView">
                    {separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}
                </span>
            </div>

        )
    }
}