import { ReactInstaStories } from "../../../react-insta-stories/reactInstaStories";
import { Story } from "../../../react-insta-stories/interfaces";
import { StoriesEntity } from "./storiesAutoComponenta";
import { SiteMedia } from "../../../../siteWidgets/siteMedia";

interface StoriesItemProps {
    entity: StoriesEntity
    onStoriesClose: () => void
    activeEntityIndex: number
    index: number
    onStoryNext: () => void
}

interface StoriesItemState {
    isPaused: boolean
    stories: Story[]
    currentIndex: number
    isMuted: boolean
}

export class StoriesItemMobile extends React.Component<StoriesItemProps, StoriesItemState> {
    constructor(props) {
        super(props);
        this.state = {
            isPaused: true,
            stories: null,
            currentIndex: 0,
            isMuted: false,
        }
    }

    componentDidMount() {
        this.getStories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeEntityIndex !== this.props.activeEntityIndex) {
            this.getStories();
        }
    }

    public getStories() {
        const { entity, activeEntityIndex, index } = this.props;
        const storiesArray = entity.linkedItems.map((linkedItem) => {
            const isVideoStory = linkedItem.hasVideo;
            const url = isVideoStory && linkedItem.video ? linkedItem.video[0].high_res_link : "https://vod-progressive-dev.ynethd.com/0324/1903_test_player_9_16_720p.mp4";
            return { url, type: 'video', muted: index === activeEntityIndex ? false : true }
        });
        this.setState({ stories: storiesArray });
    }

    public onToggleMute = () => {
        const videoElement = document.querySelector(".storiesDisplayWrapper .slick-active .storyContent video") as HTMLVideoElement;
        if (!videoElement) return;
        videoElement.muted ? this.setState({ isMuted: false }, () => videoElement.muted = false) : this.setState({ isMuted: true }, () => videoElement.muted = true);
    }

    public setStoryCurrentIndex = (slotId: string, index: number) => {
        localStorage.setItem(slotId, index.toString())
    }

    public getStoryCurrentIndex = (slotId: string) => {
        const currentIndex = localStorage.getItem(slotId);
        this.setState({ currentIndex: +currentIndex });
    }

    public onNextStory = () => {
        const { currentIndex, stories } = this.state;
        const { entity: { slotId }, onStoryNext } = this.props;
        if (currentIndex === stories.length - 1) return onStoryNext();

        this.setState({ currentIndex: this.state.currentIndex + 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
        console.log("onNextStory")
    }

    public onPrevStory = () => {
        const { currentIndex } = this.state;
        const { entity: { slotId } } = this.props;
        if (currentIndex === 0) return;
        this.setState({ currentIndex: this.state.currentIndex - 1 }, () => this.setStoryCurrentIndex(slotId, this.state.currentIndex));
        console.log("onPrevStory")
    }

    public render() {
        const { onStoriesClose, entity: { promotionName, media, linkTarget, link }, onStoryNext } = this.props;
        const { stories, currentIndex, isMuted } = this.state;
        const slotClass = `slotView ${isMuted ? "muted" : ""}`;
        
        return (
            <div className={slotClass}>
                <div className="closeBtn" onClick={onStoriesClose}></div>
                <div className="muteBtn" onClick={this.onToggleMute}></div>
                <div className="storyItems">
                    {stories &&
                        <div className="mainStory">
                            <ReactInstaStories
                                stories={stories}
                                defaultInterval={20000}

                                // width={}
                                // height={709}
                                // isPaused={isPaused}

                                onNext={this.onNextStory}
                                onPrevious={this.onPrevStory}
                                onAllStoriesEnd={onStoryNext}
                                currentIndex={currentIndex}
                            // onStoryStart={this.onStoryStart}
                            // preventDefault={true}
                            />
                            <div className="moreDetails" >
                                <div className="shareBtn"></div>
                                <div className="mediaArea">
                                    <div className="slotTitle">{promotionName}</div>
                                    <div className="MediaCarousel">
                                        <SiteMedia
                                            data={media}
                                            width={50}
                                            height={50}
                                            videoSettings={null}
                                            itemLinkUrl={link.link.publishedLink}
                                            isImageLinkable={true}
                                            linkTarget={linkTarget}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}