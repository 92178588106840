import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { Group } from "./siteYnetHeaderComponenta";
import { YnetSearchForm } from "../../../ynetSearch/components/ynetSearchForm/ynetSearchForm";
import * as classNames from "classnames"
const clickOutside = require('react-click-outside');

interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisibleMenu: boolean
    isVisibleSearch: boolean
    onCloseMenu: () => void
    onCloseSearch: () => void
    followUsText: string
    linkToInst: string
    linkToFb: string
    linkToTw: string
    linkToYtube: string
    ynetSearchPageLink: string
}

@clickOutside
export class MenuPopupComponenta extends React.Component<MenuPopUpProps, {}> {

    private handleClickOutside = (e) => {
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;
        if (isVisible && e.target && e.target.id !== "wcm_MenuDisplay" && e.target.id !== "wcm_MenuDisplay_moreBtn" && e.target.id !== "wcm_MenuDisplay_searchIcon" && e.target.id !== "wcm_SearchDisplay") {
            this.props.onCloseMenu();
            this.props.onCloseSearch();
        }

    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }
    render() {
        const { groupsWithItems, isVisibleMenu, followUsText, linkToInst, linkToFb, linkToTw, linkToYtube, ynetSearchPageLink, isVisibleSearch } = this.props
        const isVisible = this.props.isVisibleMenu || this.props.isVisibleSearch;

        return (
            <div className={classNames("popUpmenu", { "opened": isVisible, "closed": !isVisible, "searchPopUp": this.props.isVisibleSearch })} role="sub-navigation" aria-label="Sub Menu">
                <YnetSearchForm placeholder="חיפוש באתר" ynetSearchPageLink={ynetSearchPageLink} />
                {!isVisibleSearch && <>  <div id="mainAccessibilityButton" >
                    <a id="INDmenu-btn" onClick={this.clickAcessibilityBtn} title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button">
                        <span className="icon" ></span>
                    </a></div>
                    <div className="linksGroups">
                        {_.map((groupsWithItems), (item: Group, index: number) =>
                            <div className="group" key={index}>
                                <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                                <ul className="groupList">
                                    {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                        <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                            <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                        </HyperLinkBuilder></li>
                                    )}
                                </ul>
                            </div>
                        )}</div>
                    <div className="followUsLinks">
                        {followUsText !== "" && <div className="followUs">{followUsText}</div>}
                        <div className="mediaLinks">
                            {linkToTw !== "" && <SimpleLinkRenderer href={linkToTw} target="_blank" className="mediaIcon linkToTw" />}
                            {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                            {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}
                            {linkToYtube !== "" && <SimpleLinkRenderer href={linkToYtube} target="_blank" className="mediaIcon linkToYtube" />}
                        </div>
                    </div>
                </>}
            </div>)
    }
}