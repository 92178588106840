const clickOutside = require('react-click-outside');


interface fontSliderState {
    fontValue: number
}
interface fontSliderProps {
    onClose: () => void
    lang:string
}

const translationDict = {
    "en": {
        "Font size in the article": "Font size in the article",
        "Back to default": "Back to default",
        "close": "close",
        "A": "A",
    },
    "he": {
        "Font size in the article": "גודל גופן בכתבה",
        "Back to default": "חזרה לברירת המחדל",
        "close": "סגירה",
        "A": "א",
    },
    "ru":{
        "Font size in the article":"Размер шрифта",
        "Back to default": "вернуться",
        "close": "закрыть",
        "A":"A"

    },
    "ar":{
        "Font size in the article":"حجم الخط في المقال",
        "Back to default": "العودة إلى الافتراضي",
        "close": "ليغلق",
        "A":"أ" 
    }
}
@clickOutside
export class FontSlider extends React.Component<fontSliderProps, fontSliderState> {
    public fontSlider: HTMLDivElement;
    constructor(props) {
        super(props)
        let fontValue = 1;
        if (localStorage.fontSize) {
            fontValue = localStorage.fontSize;
        }
        this.state = {
            fontValue: fontValue * 100,

        }
    }
    private handleClickOutside = () => {
        this.props.onClose();
    }


    private updateSliderValue = (e) => {
        const value = parseInt(e.target.value);
        this.setState({ fontValue: value }, () => {
            const fontSize = this.state.fontValue / 100;
            localStorage.fontSize = fontSize;
            document.documentElement.style.fontSize = fontSize * 16 + "px";
        })
    }

    private resetValue = () => {
        this.setState({ fontValue: 100 }, () => {
            localStorage.fontSize = 1;
            const fontSize = this.state.fontValue / 100;
            document.documentElement.style.fontSize =  fontSize * 16+ "px";
            this.props.onClose()
        })

    }

    private FontSliderWindowTranslation = (key: string) => {
        const {lang} = this.props;
        return translationDict[lang || "he"][key]
    }


    public render() {
        const { fontValue } = this.state;

        return (
            <div className="FontSliderWindow"  >
                <div className="FontSliderWindowHeader">
                    <div className="changeFontTitle">{this.FontSliderWindowTranslation("Font size in the article")}</div>
                    <div className="resetSize" onClick={this.resetValue}>{this.FontSliderWindowTranslation("Back to default")}</div>
                </div>
                <div className="inputArea">
                    <span className="fontsize small">{this.FontSliderWindowTranslation("A")}</span>
                    <input type="range" min="100" max="150" value={`${fontValue}`} id="font-slider" onChange={this.updateSliderValue}></input>
                    <span className="fontsize big">{this.FontSliderWindowTranslation("A")}</span>
                </div>
                <button className="closeWindow" onClick={this.props.onClose}>{this.FontSliderWindowTranslation("close")}</button>
            </div>

        )
    }
}
