import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { DateDisplay } from "../../../commonComponents/inline/dateDisplay/dateDisplay"
import { CalcalistMutamShareMenu } from "./calcalistMutamShareMenu";
import { RenderReactAtSite } from "../../../commonComponents/inline/renderReactAtSite";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"

interface GeneralSlotItemProps {
    fieldsDisplaySettings?: Partial<IFieldDisplayData>  
    item:GeneralItemProp
}

export interface GeneralItemProp{
    title: string
    roofTitle?: string
    linkTarget?: LinkTargetProperties
    link: string
    imageSrc: string
    publishedDate: Date
    author: string
    hasVideo?:boolean
}

export class StandartSlotItemMutam extends React.Component<GeneralSlotItemProps, {}>{
   
    public render() {
        const {item,  fieldsDisplaySettings, } = this.props
        const {title, link, linkTarget, roofTitle, imageSrc, publishedDate, author,  hasVideo} = item
        const basiclinkTargetProperties = {
            linkOpenType: "same",
            lightBoxWidth: 803,
            lightBoxHeight: 700,
            lightBoxOpacity: 70,
            lightBoxBgColor: "#000000",
        } as LinkTargetProperties;
        return (

            <article className="slotItem standart">    
                    <div className="imageArea">
                    <HyperLinkBuilder href={link} linkTarget={linkTarget ? linkTarget : basiclinkTargetProperties} title={title}>
                        <img src={imageSrc} title={title} alt={title} />
                        {hasVideo && <span className="videoIcon-pf"></span>}
                        </HyperLinkBuilder>
                        <RenderReactAtSite>
                        <CalcalistMutamShareMenu url={link} title={title}/>
                        </RenderReactAtSite>
                        
                    </div>
                    <HyperLinkBuilder href={link} linkTarget={linkTarget ? linkTarget : basiclinkTargetProperties} title={title} className="textArea">
                        {roofTitle && roofTitle.length>0 && <div className="roofTitle" data-roof-text={roofTitle}><span>{roofTitle}</span></div>}
                        <h5 className="slotTitle">{title}</h5>
                        <div className="slotDetails">
                            {fieldsDisplaySettings.isDateVisible && <DateDisplay date={publishedDate} hiddenYesterday={true} />}
                            {fieldsDisplaySettings.isDateVisible && fieldsDisplaySettings.isAuthorVisible && author.length>0 && <span className="separator"> | </span>}
                            {fieldsDisplaySettings.isAuthorVisible && <span>{author}</span>}
                        </div>
                        </HyperLinkBuilder>          
            </article>
        )
    }

}