import { openFacebookShare, analyticsPushFunc, openWhatsAppShare, openTelegramShare, openTwitterShare, openMailShare } from "../../../../widgetsUtils"
import { getTalkbackNumberView, toggleSpotImPopUp } from "../articleSocialShare1280Component/articleSocialShareNew1280";
import { FrontCopyButton } from "../../../../commonComponents/inline/frontCopyButton/frontCopyButton";

interface ArticleAppHeaderShareProps {
    url: string
    title?: string
    lang?: string
    domain: string
    isSpotim?: boolean
}
interface ArticleAppHeaderShareState {
    showComment: boolean
    wcmNumOfComments: string
}
export class ArticleMobileHeaderShareComponent extends React.Component<ArticleAppHeaderShareProps, ArticleAppHeaderShareState>{
    public static siteScriptName = "ArticleMobileHeaderShareComponent";
    public ON_WCM_COMMENTS_RECIVED = "onWcmCommetnsRecived";
    constructor(props) {
        super(props)
        this.state = {
            showComment: true,
            wcmNumOfComments: '',
        }
    }
    private openShare = async () => {
        const { domain } = this.props;
        const current_url = `${window.location.href}?utm_source=${domain}&utm_medium=social&utm_campaign=general_share`;
        const shareData = { url: current_url }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    private handleCommentsPopupVisability = () => {
        if (this.props.isSpotim) {
            const commentsPopUp = document.querySelector('.spotimWrapper');
            if (commentsPopUp) {
                toggleSpotImPopUp(commentsPopUp)
            }
        }
        else if (typeof window !== "undefined") {
            window.handleCommentsPopupVisability && window.handleCommentsPopupVisability();
        }
    }

    componentDidMount() {
        document.addEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
        const comments = document.getElementById("SiteArticleComments") || document.querySelector(".spotimWrapper");
        const isBlogArticle = document.querySelector(".articleBlogsSpotimWrapper");

        if (!comments || isBlogArticle) {
            this.setState({
                showComment: false
            })
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener(this.ON_WCM_COMMENTS_RECIVED, this.handleOnWcmCommentsRecived);
    }

    private handleOnWcmCommentsRecived = (e) => {
        this.setState({ wcmNumOfComments: e.detail.wcmNumOfComments });
    };

    render() {
        const { url, title, lang, isSpotim } = this.props;
        const { showComment, wcmNumOfComments } = this.state;
        const numOfComments = getTalkbackNumberView(wcmNumOfComments.toString());
        const isHebrew = lang.includes("he");
        let commentsText = "תגובות";

        if (lang === "en") {
            commentsText = "Comments"
        }
        if (lang === "ru") {
            commentsText = "Комментарии: "
        }
        if (lang === "ar") {
            commentsText ="التعليقات"
        }
        if (numOfComments.length === 0) {
            commentsText = "הוספת תגובה"
            if (lang === "en") {
                commentsText = "Add a comment"
            }
            if (lang === "ru") {
                commentsText = "Обсудить"
            }
            if (lang === "ar") {
                commentsText ="اضف تعليق"
            }
        }

        const text = lang === "ru" ? `${commentsText} ${numOfComments} ` : ` ${numOfComments}   ${commentsText}`
        const telegramButton = lang === "ru" ? <div className="icon tgBtn iconShare-pf" onClick={() => openTelegramShare(url, () => analyticsPushFunc("Telegram", "Article", title), "Article")}></div> : null;
        const twitterBtn = lang === "ar" ? <div className="icon twBtn iconShare-pf" onClick={() => openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article", title), "Article")}></div> : null;
        const mailBtn = lang === "ar" ? <div className="icon mailto iconShare-pf" onClick={() => openMailShare(title, "radionas", url, () => analyticsPushFunc("Mail", "Article", title), "Article")}></div> : null;
        const isApp = typeof window !== "undefined" && window.location.href.indexOf("IsApp") != -1;
        return (
            <div className="commentsAndShareWrapper">
                <div className="articleShareButtons" >
                    <div className="icon fcBtn iconShare-pf" onClick={() => openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article", title), "Article Main Image")}></div>
                    <div className="icon whBtn iconShare-pf" onClick={() => openWhatsAppShare(url, () => analyticsPushFunc("Whatsapp", "Article", title), "Article")}></div>
                    {telegramButton}
                    {twitterBtn}
                    {mailBtn}
                   {isApp ? <a href="share_article" title={"share"} className="icon shareBtn iconShare-pf"></a> : <div className="icon shareBtn iconShare-pf" onClick={this.openShare}></div>} 
                    {isHebrew && <FrontCopyButton popupText="הועתק אל הלוח" />}
                </div>

                {showComment && <div className="commentsBtn mutamCommentsBtn-pf" onClick={this.handleCommentsPopupVisability}>
                    <div className="commentsIcon commentsIcon-pf"></div>
                    {isSpotim ? <span id="spotimCmtCounter" /> : text}
                </div>}
            </div>
        )
    }
}
