import { imageListGalleryCalcalist } from "./articleImageSliderEditor";
import { MobileArticleImagesPopUp } from "./popUpGalleryMutam"

interface ArticleImageGalleryProps {
    imageList: imageListGalleryCalcalist[]
    articleTitle:string
    articleId:string
}

interface ArticleImageGalleryState {
    isGalleryOpened:boolean
}


export class ArticleImageGalleryMutamCalcalist extends React.Component<ArticleImageGalleryProps, ArticleImageGalleryState> {
    public static siteScriptName = "ArticleImageGalleryMutamCalcalist"
    constructor(props) {
        super(props)
        this.state = {
            isGalleryOpened:false
        }
    }
    private openGallery =()=>{
        this.setState({isGalleryOpened:true})
    }
    private closeGallery =()=>{
        this.setState({isGalleryOpened:false})
    }
    public render() {
        const { imageList , articleTitle, articleId} = this.props;
        const { isGalleryOpened } = this.state;
        const imgNum = imageList.length;

        return (
            <div className="ArticleImageGalleryComponenta" onClick={this.openGallery}>

                <img src={imageList[0].imageSrc} title={imageList[0].caption} />
                <span className="iconViewGallery-pf"></span>
                <span className="caption-image-credits-new-design">{`גלריית כלכליסט: (${imgNum} תמונות)`}</span>
{isGalleryOpened &&   <MobileArticleImagesPopUp onClose={this.closeGallery} images={imageList} articleId={articleId} articleTitle={articleTitle} />}
            </div>
        )
    }
}