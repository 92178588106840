import { MainMobileHeaderItem, SecondaryMobileHeaderItem } from "./categoryHeaderMutamWrapper";
import { HyperLinkBuilder, SimpleLinkRenderer } from "../../../../../commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "../../../../../commonComponents/inline/siteSimpleImage";

interface categoryHeaderMutamSideMenuProps {
    mainItems: MainMobileHeaderItem[]
    secondaryItems: SecondaryMobileHeaderItem[]
    showSideMenu: boolean
}

export const CategoryHeaderMutamSideMenu = (props: categoryHeaderMutamSideMenuProps) => {
    const { mainItems, secondaryItems, showSideMenu } = props
    return (
        <div className={`category-header-mutam-side-menu ${showSideMenu ? "show" : "hide"}`}>
            <div className="header-categories-list">
                {/* <div className="settings-line">
                    <div className="settings-menu-btn"></div>
                </div> */}

                <div className="header-items main-items">
                    {mainItems.map(item =>
                        <SimpleLinkRenderer href={item.link} linkTarget={item.linkTarget} ariaLabel={item.title} className="header-item main-item" key={item.itemId}>
                            {item.sideMenuImage && <SiteSimpleImage src={item.sideMenuImage} alt={item.title} title={item.title} width={15} height={15} />}
                            <div className="item-title">{item.title}</div>
                        </SimpleLinkRenderer>
                    )}
                </div>

                <div className="separator"></div>

                <div className="header-items secondary-items">
                    {secondaryItems.map(item =>
                        <SimpleLinkRenderer href={item.link} linkTarget={item.linkTarget} ariaLabel={item.title} className="header-item secondary-item" key={item.itemId}>
                            <div className="item-title">{item.title}</div>
                        </SimpleLinkRenderer>
                    )}
                </div>
            </div>

            <div className="side-menu-black-menu">
                <SimpleLinkRenderer href="//m.calcalist.co.il/Search.aspx" className="black-menu-link" title="חיפוש">
                    <div className="menu-icon search-icon" />
                    <span className="menu-title">חיפוש</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="//m.calcalist.co.il/Finance.aspx" className="black-menu-link" title="פורטל פיננסי">
                    <div className="menu-icon finance-icon" />
                    <span className="menu-title">פורטל פיננסי</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="//m.calcalist.co.il/VideoChanel.aspx" className="black-menu-link" title="TV כלכליסט">
                    <div className="menu-icon tv-icon" />
                    <span className="menu-title">TV כלכליסט</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="//m.calcalist.co.il/Podcasts.aspx" className="black-menu-link" title="פודקאסט כלכליסט">
                    <div className="menu-icon podcast-icon" />
                    <span className="menu-title">פודקאסט כלכליסט</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="//www.calcalist.co.il/redmail" className="black-menu-link" title="דואר אדום">
                    <div className="menu-icon red-mail-icon" />
                    <span className="menu-title red-mail-title">דואר אדום</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="javascript: void(0);" className="black-menu-link" title="נגישות" data-uw-rm-brl="FX" data-uw-original-href="javascript: void(0);">
                    <div className="menu-icon accessability-icon" />
                    <span className="menu-title">נגישות</span>
                </SimpleLinkRenderer>
            </div>

            <div className="side-menu-mask"></div>
        </div>
    )
}
