import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../commonComponents/inline/hyperLink";
import { LinkedItemFieldDisplay } from "widgets/commonDataObjects/linkedItemsFieldsDisplay"
import { DateDisplay } from "../../../commonComponents/inline/dateDisplay/dateDisplay"
import { CalcalistMutamShareMenu } from "./calcalistMutamShareMenu";
import { RenderReactAtSite } from "../../../commonComponents/inline/renderReactAtSite";
import { SiteMedia } from "../../../siteWidgets/siteMedia"
import { SiteVideoData } from "../../../interfaces"
import { VideoArchiveArticle } from "../videoArchive/components/siteVideoArchiveComponenta"

interface GeneralSlotItemProps {
    article: VideoArchiveArticle
    fieldsDisplaySettings?: Partial<LinkedItemFieldDisplay>
    playingVideoElement: HTMLElement
    updateNewPlayingVideoElement: (newVideoRef: HTMLElement) => void
}

export class StandardVideoSlotItemMutam extends React.Component<GeneralSlotItemProps, {}>{
    private videoArticleElementRef;

    private changePlayingVideo = () => {
        const { playingVideoElement, updateNewPlayingVideoElement } = this.props

        if (playingVideoElement) {
            playingVideoElement.querySelector('video').pause()
        }
        updateNewPlayingVideoElement(this.videoArticleElementRef)
    }

    private removeRefAfterPause = () => {
        if (this.props.playingVideoElement.isEqualNode(this.videoArticleElementRef)) {
            this.props.updateNewPlayingVideoElement(null)
        }
    }

    public render() {
        const { article, fieldsDisplaySettings } = this.props
        const { title, publishedLink, dateUpdatedOnSite, author, category } = article
        const { mediaVideoId, credit, main_link, low_res_link, posterSrc, youtube_id, blockAbroadViews, videoName } = article.video;
        const basicLinkTargetProperties = {
            linkOpenType: "same",
            lightBoxWidth: 803,
            lightBoxHeight: 700,
            lightBoxOpacity: 70,
            lightBoxBgColor: "#000000",
        } as LinkTargetProperties;

        const videoData = {
            mediaType: "MEDIA_VIDEO",
            mediaId: mediaVideoId,
            credit: credit,
            url: main_link,
            downGradeUrl: low_res_link,
            poster: posterSrc,
            youtube_id: youtube_id,
            blockAbroadViews: blockAbroadViews,
            videoName: videoName,
        } as SiteVideoData;


        return (
            <article className="slotItem mutamSlotItem standart" ref={ref => this.videoArticleElementRef = ref}>
                <div className="mutamVideoArea">
                    <SiteMedia
                        width={410}
                        height={230}
                        data={videoData}
                        itemLinkUrl={null}
                        linkTarget={undefined}
                        key={videoData.mediaId}
                        isImageLinkable={false}
                        onPlayStarted={this.changePlayingVideo}
                        onPlayStopped={this.removeRefAfterPause}
                        videoSettings={{ isVideoLightbox: false, hideVideoAds: true }}
                    />

                    <RenderReactAtSite>
                        <CalcalistMutamShareMenu url={publishedLink} title={title} />
                    </RenderReactAtSite>
                </div>

                <HyperLinkBuilder href={publishedLink} linkTarget={basicLinkTargetProperties} title={title} className="textArea">
                    <div className="categoryTitleWrapper">
                        {category && <span className="category">{category.textToShow}</span>}

                        <h5 className="slotTitle">{title}</h5>
                    </div>

                    <div className="slotDetails">
                        {fieldsDisplaySettings.isDateVisible && <DateDisplay date={dateUpdatedOnSite} hiddenYesterday={true} />}
                        {fieldsDisplaySettings.isDateVisible && fieldsDisplaySettings.isAuthorVisible && author.length > 0 && <span className="separator"> | </span>}
                        {fieldsDisplaySettings.isAuthorVisible && <span>{author}</span>}
                    </div>
                </HyperLinkBuilder>
            </article>
        )
    }

}