import { SiteItemData, SiteVideoData } from "../../../../interfaces";

interface PromoCarouselProps {
    width: number
    imageWidth: number
    itemListLength: number
    marginRight: number
    togglePromoActive?: boolean
    currentPromoIndex?: number
    videoList?: SiteItemData[]
    isMobileWeb?: boolean
    pushGA4Event?: (action: string, isFeedDisplay: boolean) => void
}

interface PromoCarouselState {
    rightOffset: number
    selectedIndex: number
    isCarouselMoving: boolean
    touchStartX: number | null
}
export const numberOfImagesToView = (width, imageWidth, marginRight) => {
    const itemWidth = imageWidth + marginRight
    const numberOfImagesToView = Math.floor(width / itemWidth)
    return numberOfImagesToView;
}

export class PromoCarousel extends React.Component<PromoCarouselProps, PromoCarouselState>{

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            rightOffset: 0,
            isCarouselMoving: false,
            touchStartX: null
        }
    }

    private slotWrapperRef;
    private timeoutId;

    componentDidUpdate(prevProps, prevState) {
        const { videoList, currentPromoIndex, isMobileWeb } = this.props;
        if(!videoList.length) return;
        if (!isMobileWeb) return;
        const { isCarouselMoving } = this.state;
        const isCurrentVideoHasPromo = (videoList[currentPromoIndex].media as SiteVideoData).promoPath;
        if (this.timeoutId) clearTimeout(this.timeoutId);

        if (prevProps.togglePromoActive !== this.props.togglePromoActive) {
            this.scrollToHandler();
        } else if (!isCurrentVideoHasPromo && !isCarouselMoving) {
            this.timeoutId = setTimeout(() => {
                this.scrollToHandler();
            }, 3000)
        }
    }

    componentWillUnmount() {
       this.timeoutId && clearTimeout(this.timeoutId); 
    }

    private scrollToHandler = () => {
        const { imageWidth, marginRight, currentPromoIndex } = this.props;
        if (!this.slotWrapperRef) return;
        const scrollTo = currentPromoIndex + 1;
        this.slotWrapperRef.scrollLeft = -(scrollTo * (imageWidth + marginRight));
    }

    private onTouchStart = (e) => {
        clearTimeout(this.timeoutId);
        this.setState({ isCarouselMoving: true });
        this.setState({touchStartX: e.touches[0].clientX})
    }

    private onTouchEnd = (e) => {
        const {pushGA4Event, currentPromoIndex, itemListLength} = this.props;
        const { touchStartX } = this.state;

        setTimeout(() => {
            this.setState({ isCarouselMoving: false });
        }, 1000);

        if (touchStartX !== null && e.changedTouches.length > 0) {
            const touchEndX = e.changedTouches[0].clientX;
            const deltaX = touchEndX - touchStartX;
            const shouldPushEventRight = deltaX < 0 && currentPromoIndex > 0;
            const shouldPushEventleft = deltaX > 0 && currentPromoIndex < itemListLength - 1;

            shouldPushEventRight ? pushGA4Event(`swipe_right`, false) : shouldPushEventleft && pushGA4Event(`swipe_left`, false);
        }
    }


    private handleScrollLeftClicked = () => {
        const { selectedIndex, rightOffset } = this.state
        const { itemListLength, width, imageWidth, marginRight, pushGA4Event } = this.props
        const single_media_item_width = imageWidth + marginRight
        let newIndex = selectedIndex + 1;
        const scrollBarWidth = width + marginRight
        const lastScrollingsize = single_media_item_width - (scrollBarWidth - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) * single_media_item_width)
        const newRightOffset = rightOffset - single_media_item_width
        const lastNewOffset = rightOffset - lastScrollingsize
        if (selectedIndex < itemListLength - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) - 1) {
            this.setState({
                rightOffset: newRightOffset,
                selectedIndex: newIndex
            })
        }
        else if (selectedIndex === itemListLength - numberOfImagesToView(scrollBarWidth, imageWidth, marginRight) - 1) {
            this.setState({
                rightOffset: lastNewOffset,
                selectedIndex: newIndex
            })
        }
        pushGA4Event("swipe_left", false);
    }

    private handleScrollRightClicked = () => {
        const { selectedIndex, rightOffset } = this.state;
        const { itemListLength, width, imageWidth, marginRight, pushGA4Event } = this.props;
        const single_media_item_width = imageWidth + marginRight;
        const newIndex = selectedIndex - 1;
        const newRightOffset = rightOffset + single_media_item_width;
        const itemsToViewAmount = numberOfImagesToView(width + marginRight, imageWidth, marginRight);
        const lastItemRightOffset = rightOffset + (single_media_item_width - (width - itemsToViewAmount * (imageWidth) - (itemsToViewAmount - 1) * marginRight));
        const isLastItemDisplayed = selectedIndex !== itemListLength - itemsToViewAmount;

        if (isLastItemDisplayed) {
            if (selectedIndex > 1) {
                this.setState({
                    rightOffset: newRightOffset,
                    selectedIndex: newIndex
                })
            }
            else if (selectedIndex === 1) {
                this.setState({
                    rightOffset: 0,
                    selectedIndex: 0
                })
            }
        } else {
            this.setState({
                rightOffset: lastItemRightOffset,
                selectedIndex: newIndex,
            })
        }
        pushGA4Event("swipe_Right", false);
    }

    public render() {
        const { width, imageWidth, itemListLength, marginRight } = this.props;
        const { selectedIndex, rightOffset } = this.state;
        let isRightArrowAvailble = selectedIndex !== 0;
        let isLeftArrowAvailble = selectedIndex !== itemListLength - numberOfImagesToView(width + marginRight, imageWidth, marginRight);

        return (
            <div className="slotsContent">
                <button
                    disabled={!isRightArrowAvailble}
                    className="scrollRight arrowBtn"
                    aria-label="scroll right"
                    onClick={(e) => this.handleScrollRightClicked()} />
                <div className="frame_forList">
                    <div className="slotListWrapper" style={{ "marginRight": rightOffset }} ref={slotWrapperRef => (this.slotWrapperRef = slotWrapperRef)} onTouchEnd={this.onTouchEnd} onTouchStart={this.onTouchStart}>
                        {this.props.children}</div>
                </div>
                <button
                    disabled={!isLeftArrowAvailble}
                    aria-label="scroll left"
                    className="scrollLeft arrowBtn"
                    onClick={(e) => this.handleScrollLeftClicked()} />
            </div>)
    }
}
