import { GenericInlineVideoPlayer } from "./genericInlineVideoPlayer";
import { SiteVideoData } from "../../../interfaces";
import { AbroadBlocked } from "./abroadBlockedVideo";
import { BlogsArticleVideoAppComponenta } from "../../../defenitions/blogsAutoFeed/components/blogsArticleVideoAppComponenta";
import { getQueryStringValue } from "../../../widgetsUtils";

interface SiteVideoInlineProps {
    data: SiteVideoData;
    width?: number;
    height?: number;
    runArticleAutoPlayLogic?: boolean;
    hideVideoAds?: boolean;
    isShareButton?: boolean;
    isArticle?: boolean;
    articleId?: string;
    applyStickyness: boolean;
    articleType?: string;
    videoShareLink?: string
    onSelect?: () => void;
    onPlayStarted?: () => void;
    onPlayStopped?: () => void;
    onVideoEnded?: () => void
    onVideoError?: () => void
}

export class SiteVideoInline extends React.Component<SiteVideoInlineProps, {}> {
    public static siteScriptName = "SiteVideoInline";

    render() {
        const { data } = this.props;
        const isAbroad = typeof window !== "undefined" && window.isAbroad;
        if (data.blockAbroadViews && isAbroad) {
            return <AbroadBlocked poster={data.poster} />;
        }

        if (typeof window !== "undefined" && getQueryStringValue("IsApp") && !window.disableAppNativeVideo) {
            return <BlogsArticleVideoAppComponenta data={data} />;
        }

        return <GenericInlineVideoPlayer {...this.props} />;
    }
}
