
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SiteLayoutType } from '../../../../../pagesPage/stateInterfaces';
import { ArticleImagesFullScreenGallery } from '../articleImagesFullScreenGallery/articleImagesFullScreenGallery';
import { ArticleImageGallery } from "./articleImageGalleryComponenta"

interface ArticleImageSliderGalleryComponentaProps {
  images: ArticleImageGallery[]
  articleId: string
  siteLayoutType: SiteLayoutType
  mainOptions: any
  componentaId?: string
  thumbOptions: any
  imagesJson?: string
}

interface ArticleImageSliderGalleryComponentaState {
  currentIndex: number
  indicatorOffset: number
  indicatorWidth: number
  gap: number
  showFullScreenGallery: boolean
}

export default class ArticleImageSliderGalleryComponenta extends React.Component<ArticleImageSliderGalleryComponentaProps, ArticleImageSliderGalleryComponentaState> {

  constructor(props: ArticleImageSliderGalleryComponentaProps) {
    super(props);
    this.state = {
      currentIndex: 0,
      indicatorOffset: 0,
      indicatorWidth: 0,
      gap: 0,
      showFullScreenGallery: false
    };
  }

  public static siteScriptName = "ArticleImageSliderGalleryComponenta";
  private mainRef;
  private thumbsRef;
  private translateX = 0;

  componentDidMount(): void {
    if (this.mainRef && this.thumbsRef && this.thumbsRef.splide) {
      this.mainRef.sync(this.thumbsRef.splide);
      let indicatorWidth = this.thumbsRef.splide.Components.Layout.slideSize(0, true);
      let indicatorWidthWithGaps = this.thumbsRef.splide.Components.Layout.slideSize(0, false);
      let gap = indicatorWidthWithGaps - indicatorWidth;
      this.setState({
        indicatorWidth,
        gap
      })
    }

  }

  renderSlides = () => {
    return this.props.images.map((item, index) => {
      const { componentaId, siteLayoutType } = this.props;
      return <SplideSlide key={item.imageId}>
        {siteLayoutType === 'mobileApp' ?
          (<a data-href="image_gallery" href={`image_gallery?componentaId=${componentaId}&index=${index}&imageId=${item.imageId}`}>
            <img src={item.imageSrc} alt={item.caption} />
          </a>)
          : <img src={item.imageSrc} alt={item.caption} />}
      </SplideSlide>
    })
  }

  handleSliderMove = (splideObj, index: number) => {
    const { indicatorWidth, gap } = this.state;
    const { thumbOptions } = this.props;
    const { perPage } = thumbOptions;
    let indicatorLineWidth = (-indicatorWidth - gap);
    if (index > perPage - 1) {
      this.translateX = indicatorLineWidth * (perPage - 1);
    } else {
      this.translateX = index * indicatorLineWidth
    }
    this.setState({
      currentIndex: index,
      indicatorOffset: this.translateX
    });
  }


  handleOpenGallery = () => {
    this.setState({ showFullScreenGallery: true })
  }

  handleCloseGallery = (index) => {
    this.setState({ showFullScreenGallery: false, currentIndex: index }, () => {
      this.thumbsRef.splide.Components.Controller.go(index)
    })
  }

  render() {

    const { images, mainOptions, thumbOptions, componentaId, siteLayoutType, imagesJson, articleId } = this.props;
    const { currentIndex, indicatorOffset, indicatorWidth, showFullScreenGallery } = this.state;

    return (
      <>
        <div className="ArticleImageSliderGalleryComponenta">
          <ArticleImagesFullScreenGallery
            open={this.handleOpenGallery}
            close={this.handleCloseGallery}
            show={showFullScreenGallery}
            siteLayoutType={siteLayoutType}
            index={currentIndex} isLtr={false}
            images={images} articleId={articleId}
          />
          {siteLayoutType === 'mobileApp' && < script type="text/javascript" dangerouslySetInnerHTML={{ __html: `const galleryImagesArrayObjects_${componentaId} = ${imagesJson}` }} />}
          <div className="main-gallery">
            {siteLayoutType === 'mobileApp' ? (<a data-href="image_gallery" href={`image_gallery?componentaId=${componentaId}&index=${currentIndex}&imageId=${images[currentIndex].imageId}`}>
              <div className="fullscreen-gallery-icon-pf"></div></a>)
              : <div onClick={() => this.setState({ showFullScreenGallery: true })} className="fullscreen-gallery-icon-pf"></div>
            }
            <Splide
              onClick={() => this.setState({ showFullScreenGallery: true })}
              onMove={this.handleSliderMove}
              options={mainOptions}
              className="main-slider"
              ref={ref => this.mainRef = ref}
            >
              {this.renderSlides()}
            </Splide>
            <div className="caption-credit">{`${images[currentIndex].caption} (${images[currentIndex].credit})`}</div>
          </div>

          <Splide
            className="thumbnail-slider"
            options={thumbOptions}
            ref={ref => this.thumbsRef = ref}
          >
            {this.renderSlides()}
          </Splide>
          <div className="article-carousel-underline-navigation">
            <div style={{ width: indicatorWidth, transform: "translateX(" + indicatorOffset + "px" + ")" }} className="underline"></div>

          </div>
        </div>
      </>
    );
  }
}