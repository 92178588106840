import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { ISlot } from "./storiesAutoWrapper";
import Slider from 'react-slick'
import { StoriesAutoMobileSlotItem } from "./storiesAutoMobileSlotItem";
import { StoriesMobileItem } from "./storiesMobileItem";
import { StoriesItemMobile } from "./storiesItemMobile";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import * as ResizeObserver from "resize-observer-polyfill";

interface StoriesAutoMobileComponentaProps {
    componentaId?: string
    fieldsDisplaySettings: IFieldDisplayData
    hideVideoAds?: boolean
    tabBackgroundColor?: string
    slots: ISlot[]
    storiesProgressTime: number
    tabTitle: string
}

interface StoriesAutoMobileComponentaState {
    storyEntities: StoriesEntity[]
    isStoriesDisplay: boolean
    activeEntityIndex: number
}

export interface StoriesEntity extends ISlot {
    linkedItems: ArticleExtraData[]
}

export class StoriesAutoMobileComponenta extends React.Component<StoriesAutoMobileComponentaProps, StoriesAutoMobileComponentaState> {
    public static siteScriptName = "StoriesAutoMobileComponenta"
    constructor(props) {
        super(props)
        this.state = {
            storyEntities: [],
            isStoriesDisplay: false,
            activeEntityIndex: 0,
        }
    }

    private storiesSlider;

    componentDidMount() {
        this.getArticles();
    }

    private getArticles = async () => {
        const { componentaId, slots } = this.props;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        try {
            const articleRequests = slots.map(async (item, index) => {
                const response = await axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/type/${item.contentSource.type}/id/${item.contentSource.link.id}`);
                // @ts-ignore
                const linkedItems = response.data.data as ArticleExtraData[];
                return {
                    ...item,
                    linkedItems,
                };
            });
            const allArticles: StoriesEntity[] = await Promise.all(articleRequests);
            this.setState({ storyEntities: allArticles });
        } catch (error) {
            console.error("Error getting headline data:", error);
        }
    }

    private onStoriesOpen = (clickedIndex: number) => {
        this.setState({ isStoriesDisplay: true, activeEntityIndex: clickedIndex });
        disablePageRefresh();
    }

    private onStoriesClose = () => {
        this.setState({ isStoriesDisplay: false });
        enablePageRefresh();
    }

    private onStoryNext = () => {
        this.storiesSlider.slickPrev();
    }

    private onStoryPrev = () => {
        this.storiesSlider.slickNext();
    }

    public render() {
        const { fieldsDisplaySettings, tabTitle } = this.props;
        const { storyEntities, isStoriesDisplay, activeEntityIndex } = this.state;

        const mobileSettings = {
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows: false,
            swipeToSlide: true,
        };

        const StoriesMobileItemSettings = {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: activeEntityIndex,
            arrows: false,
            swipeToSlide:true,

            // beforeChange: (oldIndex, newIndex) => {
            //     setTimeout(() => {
            //         this.setState({ activeEntityIndex: newIndex })
            //     }, 500);
            //     console.log(newIndex, )
            // },
            afterChange: (index) => {
                this.setState({ activeEntityIndex: index })
            }
        }

        return (
            <div className="storiesAutoMutamComponenta">
                {fieldsDisplaySettings.isTabVisible &&
                    <div className="storiesAutoComponentaTab componentaTab">
                        <div className="tabTitle">{tabTitle}</div>
                    </div>
                }
                <div className="galleryWrapper">
                    <Slider {...mobileSettings} className="slider"  >
                        {storyEntities.map((entity,index) =>
                            <StoriesAutoMobileSlotItem
                                key={entity.slotId}
                                entity={entity}
                                index={index}
                                onStoriesOpen={this.onStoriesOpen}
                            />)}
                    </Slider>
                </div>
                {isStoriesDisplay &&
                    <div className="storiesDisplayWrapper">
                        <Slider {...StoriesMobileItemSettings} className="slider" ref={storiesSlider => (this.storiesSlider = storiesSlider)}>
                            {storyEntities.map((entity, index) =>
                             <StoriesItemMobile 
                             key={entity.slotId}
                              entity={entity} 
                              index={index} 
                              onStoriesClose={this.onStoriesClose} 
                              activeEntityIndex={activeEntityIndex}
                              onStoryNext={this.onStoryNext}
                              />)}
                        </Slider>
                    </div>
                }
            </div >
        )
    }
}