
import { isWcmEditor } from "../../../../../../config/serverConfig";
import { analyticsPushFunc, openFacebookShare, openMailShare, openTwitterShare, openWhatsAppShare } from "../../../../../widgetsUtils";
import { SiteLayoutType } from "../../../../../../pagesPage/stateInterfaces";
require('./calcalist_site_calcalistPodcastShareMenu.less');
interface CalcalistPodcastShareMenuProps {
    title: string
    url: string
    siteLayoutType?: SiteLayoutType
}

export const CalcalistPodcastShareMenu = ({ title, url, siteLayoutType }: CalcalistPodcastShareMenuProps) => {

    const openMobileShare = async () => {
        const shareData = { url: url, title: title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }
    const CalcalistPodcastShareMenuDesktop = () => {
        return (
            <div className='calcalist-podcast-menu-share-container'>
                <button onClick={() => !isWcmEditor() ? openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn fb" />
                <button onClick={() => !isWcmEditor() ? openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn tw" />
                <button onClick={() => !isWcmEditor() ? openMailShare(title, "כלכליסט", url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn mail" />
                <button onClick={() => !isWcmEditor() ? openWhatsAppShare(url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="podcast-share-btn wa" />
            </div>
        )
    }
    const CalcalistPodcastShareMenuMutam = () => {
        return (
            <div className='calcalist-podcast-menu-share-mutam-container'>
                <div className="share-container">
                    <span className="share-icon-pf" onClick={openMobileShare}></span>
                    <span className="close-icon-pf" onClick={() => history.back()}></span>
                </div>
            </div>
        )
    }

    const CalcalistPodcastShareMenuApp = () => {
        return (
            <div className='calcalist-podcast-menu-share-mutam-container'>
                <div className="share-container">

                    <a href="share_article">
                        <span className="share-icon-pf" />
                    </a>
                    <a href={"javascript:history.back()"} className="close-icon-pf" title="back" />
                </div>
            </div>
        )
    }

    switch (siteLayoutType) {
        case 'mobileWeb':
            return <CalcalistPodcastShareMenuMutam />
        case 'desktop':
            return <CalcalistPodcastShareMenuDesktop />
        case 'mobileApp':
            return <CalcalistPodcastShareMenuApp />
        default:
            return <CalcalistPodcastShareMenuDesktop />
    }
}

CalcalistPodcastShareMenu.siteScriptName = "CalcalistPodcastShareMenuComponenta"