require("./calcalist_site_commertialComponenta.less")
import Slider from 'react-slick'
import { SiteMediaData } from "widgets/interfaces"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { SiteMedia } from '../../../../siteWidgets/siteMedia';
import * as classNames from "classnames"
import * as map from "lodash/map"
import * as ResizeObserver from "resize-observer-polyfill"

export interface CommertialItemData {
    media: SiteMediaData
    title: string
    subTitle: string
    link: string
    targetLink: LinkTargetProperties
}

interface CommertialFrontComponentaMutamState {
    activeSlide: number
    screenWidth: number
}

interface CommertialFrontComponentaMutamProps {
    tabTitle: string
    itemList: CommertialItemData[]
    tabColor: string
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    titleColor: string
    subTitleColor: string
    gallerySeconds: number
}

export class CommertialFrontComponentaMutam extends React.Component<CommertialFrontComponentaMutamProps, CommertialFrontComponentaMutamState>{
    public static siteScriptName = "CommertialFrontComponentaMutam";
    constructor(props: CommertialFrontComponentaMutamProps) {
        super(props);
        this.state = {
            activeSlide: 0,
            screenWidth:0,
        }
    }

    private slider;
    private resizeObserver: ResizeObserver;

    componentDidMount() {
        const screenElement = document.querySelector("html");
        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentWidthChanged(r.contentRect.width)
        }))

        screenElement && this.resizeObserver.observe(screenElement);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect()

    }

    private goToSlide = (i) => {
        this.slider.slickGoTo(i);
        this.setState({ activeSlide: i });
    }

    private onContentWidthChanged = (screenWidth: number) => {
        this.setState({ screenWidth });
    }

    public render() {
        const { itemList, tabLink, tabLinkTarget, tabColor, tabTitle, titleColor, subTitleColor, gallerySeconds } = this.props;
        const { activeSlide,screenWidth } = this.state;
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
            autoplaySpeed: gallerySeconds * 100000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };

        return (
            <div className={`CommertialComponentaMutam`}>
                <h2 className="tabComponenta" >
                    <HyperLinkBuilder className="titleLink tabTitle" href={tabLink} linkTarget={tabLinkTarget} style={{ color: tabColor }}><div className="mainTitleText" >{tabTitle}</div></HyperLinkBuilder>
                </h2>
                <div className="commertialSlider" >
                    <div className="sliderList">
                        {map(itemList, (item, i) =>
                            <h5 className={classNames("slotTitle", { "activeSlide": i === activeSlide })} key={i} onMouseEnter={() => this.goToSlide(i)}>
                                    <div dangerouslySetInnerHTML={{ __html: item.title }} style={{ color: titleColor }} /> 
                            </h5>
                        )}

                    </div>
                    <Slider ref={slider => (this.slider = slider)} {...settings} className="slider"  >
                        {map(itemList, (item, index) =>
                            <div className="slotView" key={index}>
                                <div className="imgArea">
                                    <SiteMedia
                                        isImageLinkable={true}
                                        itemLinkUrl={item.link}
                                        linkTarget={item.targetLink}
                                        data={item.media}
                                        videoSettings={null}
                                        key={item.media.url} 
                                        width={screenWidth}
                                        height={200}
                                    />
                                </div>                
                                    <div dangerouslySetInnerHTML={{ __html: item.subTitle }} className="subTitle" style={{ color: subTitleColor }}/>
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        )
    }
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickPrev"
            onClick={onClick}
        />
    );
}

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slickArrow slickNext"
            onClick={onClick}
        />
    );
}
