import { ArticleExtraData } from "articlePage/stateInterfaces"
import { MusafArticleItemSlot } from "./musafArticleItemSlot";
interface MusafArticleListProps {
    itemList: ArticleExtraData[]
}

export class MusafArticleList extends React.Component<MusafArticleListProps, {}>{
    public render() {
        const { itemList,  } = this.props
        return (
            <div className="MusafArticleList slotList" >
                {itemList.map((item, i) =>
                    <MusafArticleItemSlot
                        key={i}
                        article={item}
                    />)}
            </div>
        )
    }
}