import { SiteVideoData, YnetTvSiteVideoData } from "../../../interfaces";

interface Props {
    data: SiteVideoData | YnetTvSiteVideoData;
}

interface State {}

export class BlogsArticleVideoAppComponenta extends React.Component<
    Props,
    State
> {
    render() {
        const { data } = this.props;
        const {aspectRatio} = data;

        return (
            <div className="blogsArticleVideoAppComponenta">
                <div className="artismlimg androidTvImageClass">
                    <div className="tvVideo_wraper_new_design">
                        <a
                            className="tvVideo_wraper tvVideo_wraper_new_design"
                            href={`//www.ynet.co.il/video?path=${
                                data.url
                            }?videoId=${data.mediaId}&advertisement=${"1"}${aspectRatio ? `&proportion=${aspectRatio}` : ""}`}
                        >
                            <img
                                width="100%"
                                src={data.poster}
                                alt="ןעוט..."
                                title="ןעוט..."
                                border="0"
                            />
                            <div
                                style={{ backgroundSize: "64px 64px" }}
                                className={"playButton"}
                            />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
