import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { SiteVideoData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { VideoSettingsData } from "widgets/commonDataObjects/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { separateHourFromDateAndFormatToString } from "../../../../widgetsUtils"
import { CalcalistShareMenu } from "../../../../commonComponents/base/CalcalistShareMenu"
import { convertHtmlToString } from "../../../../../config/utils"
import { CalcalistMutamShareMenu } from "../../generalMutamComps/calcalistMutamShareMenu"

export interface videoList {
    video: SiteVideoData
    link: string
    linkTarget: LinkTargetProperties
    title: string
    id: string
    subTitle?: string
    author?: string
    date?: Date
}

interface MobileTvCalcalistProps {
    videoItems: videoList[]
    videoSettings: VideoSettingsData
    numOfItems: number
    titleColor: string
}

export class MobileTvCalcalist extends React.Component<MobileTvCalcalistProps, {}>{
    public static siteScriptName = "MobileTvCalcalist";
    constructor(props) {
        super(props);
    }

    render() {
        const { videoItems, videoSettings, titleColor } = this.props
        return (
            <div className="mobile-tv-calcalist">
                {videoItems.map((item, index) => {
                    if (item && index === 0) {
                        return (
                            <MobileTvCalcalistItem
                                key={item.id}
                                isMainItem={true}
                                item={item}
                                videoSettings={videoSettings}
                                titleColor={titleColor}
                            />
                        );
                    } else if (item && index === 1) {
                        return (
                            <div className="bottom-items" key="bottom-items">
                                <MobileTvCalcalistItem
                                    item={item}
                                    videoSettings={videoSettings}
                                    titleColor={titleColor}
                                />
                                {videoItems.length > 2 && (
                                    <MobileTvCalcalistItem
                                        item={videoItems[2]}
                                        videoSettings={videoSettings}
                                        titleColor={titleColor}
                                    />
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        )
    }
}


interface MobileTvCalcalistItemProps {
    isMainItem?: boolean
    item: videoList
    videoSettings: VideoSettingsData
    titleColor: string
}

export class MobileTvCalcalistItem extends React.Component<MobileTvCalcalistItemProps, { videoPlayStarted: boolean }>{
    constructor(props) {
        super(props);
        this.state = {
            videoPlayStarted: false
        }
    }

    private handleOnVideoPlayStarted = () => {
        this.setState({ videoPlayStarted: true })
    }

    private handleOnVideoPlayStopped = () => {
        this.setState({ videoPlayStarted: false })
    }

    render() {
        const { item, titleColor, videoSettings, isMainItem } = this.props;
        return (
            <div className="mobile-tv-calcalist-item">
                <div className="videoPlayerPlaceholder ">
                    {isMainItem && <CalcalistMutamShareMenu url={item.link} title={convertHtmlToString(item.title)} />}
                    {item.video !== null ? <SiteMedia
                        key={item.video.mediaId}
                        onPlayStarted={() => this.handleOnVideoPlayStarted()}
                        onPlayStopped={() => this.handleOnVideoPlayStopped()}
                        data={item.video} videoSettings={videoSettings} width={300} height={168} />
                        : <div />}
                </div>
                <div className="item-body">
                    <HyperLinkBuilder className="title" href={item.link} linkTarget={item.linkTarget}>
                        <div style={{ color: titleColor }} dangerouslySetInnerHTML={{ __html: item.title }} />
                    </HyperLinkBuilder>
                    <div className="more-details">
                        <span className="dateView">{separateHourFromDateAndFormatToString(item.date,
                            { showHour: false, showDate: isMainItem })} </span>
                        {item.author &&
                            <>
                                <span className="commaView"> | </span>
                                <span className="authorInfo" dangerouslySetInnerHTML={{ __html: item.author }} />
                            </>}
                    </div>
                </div>

            </div>
        )
    }


}