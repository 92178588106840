import { RadioProgramJsonItem } from "../../../../jsonSerializer/radioScheduleJsonSerializer"
import { YnetLiveFloatingPlayer } from "./ynetLiveFloatingPlayer";
import { YnetLiveAudioPlayButton, AudioRendererProps } from "./ynetLiveAudioPlayButton";
import { AdsProgressBar } from "./adProgressBar";
import { FloatingVideoLivePlayer } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/floatingVideoLivePlayer/floatingVideoLivePlayer";
import { isWcmEditor } from "../../../../../config/serverConfig";
import { buildAdTag } from "../../../radioWidgets/floatingPlayer/imaAdTagBuilder";
import * as ResizeObserver from "resize-observer-polyfill"

const HLS_LOADED_EVENT = "hls_lodaed";

export interface RadioProgramItem extends Partial<RadioProgramJsonItem> {
    startTime: string
    endTime: string
}

export interface YnetLiveComponentaProps {
    isMobileWeb: boolean
    programTitle: string
    liveBroadcastSource: string
    floatingVideoLivePlayerSource: string
}

interface YnetLiveComponentaState {
    isAdPlaying: boolean
    isVideoFloatingPlayerVisible: boolean
    isHlsLoaded: boolean,
    isHlsLoadingError: boolean,
    floatingPlayerBottomHeight: number
}

export class YnetLiveComponenta extends React.Component<YnetLiveComponentaProps, YnetLiveComponentaState> {
    public static siteScriptName = "YnetLiveComponenta"

    constructor(props) {
        super(props)
        this.state = {
            isAdPlaying: false,
            isVideoFloatingPlayerVisible: false,
            isHlsLoaded: false,
            isHlsLoadingError: false,
            floatingPlayerBottomHeight: 0
        }
        if (!isWcmEditor() && typeof window !== 'undefined' && !document.getElementById("hlsjsscript")) {
            const script = document.createElement('script')
            script.id = "hlsjsscript"
            script.src = 'https://cdn.jsdelivr.net/npm/hls.js@1.2.3'
            script.onload = () => document.dispatchEvent(new CustomEvent(HLS_LOADED_EVENT));
            script.onerror = this.updateHlsLoadError
            document.head.appendChild(script)
        }
    }

    private resizeObserver: ResizeObserver;

    componentDidMount() {
        document.addEventListener(HLS_LOADED_EVENT, this.updateHlsLoaded);

        const elementId = this.props.isMobileWeb ? "ads.banner" : "ads.sargel"
        const stickyAdElement = document.getElementById(elementId)
        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentHeightChanged(r.contentRect.height)
        }))
        stickyAdElement && this.resizeObserver.observe(stickyAdElement);
    }

    componentWillUnmount() {
        document.removeEventListener(HLS_LOADED_EVENT, this.updateHlsLoaded)
        this.resizeObserver && this.resizeObserver.disconnect()
    }

    private onContentHeightChanged = (height: number) => {
        this.setState({ floatingPlayerBottomHeight: height })
    }

    private updateHlsLoaded = () => {
        this.setState({ isHlsLoaded: true })
    }

    private updateHlsLoadError = () => {
        this.setState({ isHlsLoadingError: true })
    }

    private onAdPlayingStateChange = (isAdPlaying) => {
        this.setState({ isAdPlaying })
    }

    private onVideoFloatingPlayerClose = () => {
        this.setState({ isVideoFloatingPlayerVisible: false });
    }


    render() {
        const { isMobileWeb, liveBroadcastSource, programTitle, floatingVideoLivePlayerSource } = this.props;
        const { isAdPlaying, isVideoFloatingPlayerVisible, floatingPlayerBottomHeight } = this.state;
        const browserDisplayMediaMetaData = { title: programTitle, artist: "ynet" };
        const sourceImage = isMobileWeb ? "https://www.ynet.co.il/images/live/mobile.gif" : "https://www.ynet.co.il/images/live/logo.gif";
        const mainTitle = isMobileWeb ? "האזינו לשידור" : "עכשיו בשידור";
        const adTagUrl = buildAdTag(isMobileWeb);
        const { isHlsLoaded, isHlsLoadingError } = this.state;
        if (!isHlsLoaded && !isHlsLoadingError) return null;

        return (
            <div className="ynet-live-componenta">
                <img className="main-logo" src={sourceImage} />
                <div className="text-div">
                    <div className="main-title">{mainTitle}</div>
                    <div className="separator"></div>
                    <div className="program-title">{programTitle}</div>
                </div>

                {isMobileWeb ?
                    <YnetLiveAudioPlayButton
                        audioUrl={liveBroadcastSource}
                        durationSeconds={0}
                        shouldAppearOnComponentOutOfView={true}
                        browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                        isAdPlaying={isAdPlaying}
                        adTag={adTagUrl}
                        onAdPlayingStateChange={this.onAdPlayingStateChange}
                        onVideoFloatingPlayerClose={this.onVideoFloatingPlayerClose}
                        programTitle={programTitle}
                        isVideoFloatingPlayerVisible={isVideoFloatingPlayerVisible}
                        renderer={(props: AudioRendererProps) => (<YnetLiveRadioMobileWeb {...props} />)}
                        floatingPlayerRenderer={(rendererProps) => (
                            <YnetLiveFloatingPlayer
                                isMobileWeb={isMobileWeb}
                                isAudioPlaying={rendererProps.isAudioPlaying}
                                isFloatingPlayerVisible={isVideoFloatingPlayerVisible ? false : rendererProps.isFloatingPlayerVisible}
                                onTogglePlayPause={rendererProps.onTogglePlayPause}
                                onClose={rendererProps.onClose}
                                title={programTitle}
                                floatingPlayerBottomHeight={floatingPlayerBottomHeight}
                            >
                                <AdsProgressBar
                                    adDuration={rendererProps.adDuration}
                                    adPlaybackTime={rendererProps.adPlaybackTime}
                                    isAdPlaying={isAdPlaying} />
                            </YnetLiveFloatingPlayer>)}
                    />
                    :
                    <div className="buttons-div">
                        <button className="video-button btn" onClick={() => this.setState({ isVideoFloatingPlayerVisible: true })}></button>
                        <YnetLiveAudioPlayButton
                            audioUrl={liveBroadcastSource}
                            durationSeconds={0}
                            shouldAppearOnComponentOutOfView={true}
                            browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                            isAdPlaying={isAdPlaying}
                            adTag={adTagUrl}
                            onAdPlayingStateChange={this.onAdPlayingStateChange}
                            onVideoFloatingPlayerClose={this.onVideoFloatingPlayerClose}
                            programTitle={programTitle}
                            isVideoFloatingPlayerVisible={isVideoFloatingPlayerVisible}
                            renderer={(props: AudioRendererProps) => (<YnetLiveRadio {...props} />)}
                            floatingPlayerRenderer={(rendererProps) => (
                                <YnetLiveFloatingPlayer
                                    isMobileWeb={isMobileWeb}
                                    isAudioPlaying={rendererProps.isAudioPlaying}
                                    isFloatingPlayerVisible={isVideoFloatingPlayerVisible ? false : rendererProps.isFloatingPlayerVisible}
                                    onTogglePlayPause={rendererProps.onTogglePlayPause}
                                    onClose={rendererProps.onClose}
                                    title={programTitle}
                                    floatingPlayerBottomHeight={floatingPlayerBottomHeight}
                                >
                                    <AdsProgressBar
                                        adDuration={rendererProps.adDuration}
                                        adPlaybackTime={rendererProps.adPlaybackTime}
                                        isAdPlaying={isAdPlaying} />
                                </YnetLiveFloatingPlayer>)}
                        />
                        {this.state.isVideoFloatingPlayerVisible &&
                            <div className="live-video-floating-player">
                                <FloatingVideoLivePlayer videoSource={floatingVideoLivePlayerSource} isYnetLive={true} onClose={this.onVideoFloatingPlayerClose} />
                            </div>
                        }
                    </div>}
            </div>
        )
    }
}

function YnetLiveRadio({ isAudioPlaying, onTogglePlayPause, }: AudioRendererProps) {
    const buttonClass = `audio-button btn ${isAudioPlaying ? "pause" : "play"}`;
    return (
        <button className={buttonClass} onClick={onTogglePlayPause}></button>
    );
}

function YnetLiveRadioMobileWeb({ isAudioPlaying, onTogglePlayPause, }: AudioRendererProps) {
    const buttonClass = `media-button ${isAudioPlaying ? "pause" : "play"}`;
    return (
        <div className={buttonClass} onClick={onTogglePlayPause}></div>
    );
}