
import { LinkedItemFieldDisplay } from "../../../../commonDataObjects/linkedItemsFieldsDisplay"
import { LinkedItemVideoSettings } from "../../../../commonDataObjects/linkedItemsVideoSettings"
import { LinkTargetProperties } from "../../../../../global/components/linkSettings/interfaces"
import { CalcalistSpecialContentItemMobile } from "./calcalistSpecialContentItemMobile";
import { CalcalistSpecialContentMobileItemData } from "./calcalistSpecialContenWrapperMobile";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";


interface CalcalistSpecialContentMobileProps {
    tabText: string
    tabLink: string
    tabLinkTarget: LinkTargetProperties
    titleTextColor: string
    stirpsColor: string
    backgroundColor: string
    isStripsVisible: boolean
    isTabVisible: boolean
    imageWidth: number
    imageHeight: number
    itemsContainerWidth: number
    height: number
    itemList: CalcalistSpecialContentMobileItemData[]
    fieldsDisplaySettings: LinkedItemFieldDisplay
    videoSettings: LinkedItemVideoSettings
    naturalWidth: number
    isBigImage: boolean
    isLTR?: boolean
    numberOfVisibleItems: number
    isMobileSettings?: boolean
}

export class CalcalistSpecialContentMobile extends React.Component<CalcalistSpecialContentMobileProps>{
    public static siteScriptName = "CalcalistSpecialContentMobile"

    render() {
        const { videoSettings, imageWidth, imageHeight, titleTextColor, itemList, isLTR, tabText, tabLink, tabLinkTarget } = this.props
        return (
            <div className={`CalcalistSpecialContentMobile`}>
                <section className="main-container">
                    <h2 className="header">
                        <HyperLinkBuilder
                            href={tabLink}
                            linkTarget={tabLinkTarget !== undefined && tabLinkTarget} >
                            <span className="title">{tabText}</span>
                            <span className="arrow-icon"></span>
                        </HyperLinkBuilder>
                    </h2>
                    <div className="items-container">
                        <div className="main-item">
                            <CalcalistSpecialContentItemMobile
                                itemData={itemList[0]}
                                videoSettings={videoSettings}
                                mediaWidth={imageWidth}
                                mediaHeight={imageHeight}
                                titleTextColor={titleTextColor}
                                isLTR={isLTR}
                            />
                        </div>
                        <div className="bottom-items">
                            <CalcalistSpecialContentItemMobile
                                itemData={itemList[1]}
                                videoSettings={videoSettings}
                                mediaWidth={imageWidth}
                                mediaHeight={imageHeight}
                                titleTextColor={titleTextColor}
                                isLTR={isLTR}
                            />
                            <CalcalistSpecialContentItemMobile
                                itemData={itemList[2]}
                                videoSettings={videoSettings}
                                mediaWidth={imageWidth}
                                mediaHeight={imageHeight}
                                titleTextColor={titleTextColor}
                                isLTR={isLTR}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}