import * as axios from "axios";
import * as classNames from "classnames";
import {
    disablePageRefresh,
    enablePageRefresh,
} from "../../../../siteWidgets/siteWidgetUtils";
interface SpotimScriptInjectorProps {
    articleId: string;
    spotimTalkbackCount?: string;
    isPopUp?: boolean;
    isFirmaApp?: boolean;
    isMobileWeb?: boolean;
    className?: string;
}

export class SpotimScriptInjector extends React.Component<SpotimScriptInjectorProps> {
    public static siteScriptName = "SpotimScriptInjector";

    componentDidMount() {
        const {
            articleId,
            spotimTalkbackCount,
            isPopUp,
            isFirmaApp,
            isMobileWeb,
        } = this.props;
        var s = document.createElement("script");
        s.onload = () => {
            axios
                .get(
                    `https://open-api.spot.im/v1/messages-count?spot_id=sp_AJXaDckj&posts_ids=${articleId}`
                )
                .then((res: any) => {
                    //   const articles = res.data.data;

                    const spotimCmtCounter =
                        document.getElementById("spotimCmtCounter");
                    const bottomSpotimCounter = document.getElementById(
                        "bottomSpotimCounter"
                    );
                    const bottomSpotimMobileWebCounter =
                        document.getElementById("bottomSpotimCounterMobileWeb");
                    const bottomSpotimCommentsExist = document.getElementById(
                        "bottomSpotimCommentsExist"
                    );
                    const siteVerticalArticleSocialShareWrapper =
                        document.getElementById(
                            "SiteVerticalArticleSocialShare_Wrapper"
                        );
                    const mutamHeaderCommentIcon = document.getElementById(
                        "commentButtonInHeader"
                    );

                    if (spotimCmtCounter) {
                        const zeroComment = "הוספת תגובה";
                        const severalComments = `${res.data.messages_count[articleId]} תגובות`;
                        const text =
                            res.data.messages_count[articleId] === 0
                                ? zeroComment
                                : severalComments;
                        spotimCmtCounter.innerText = text;
                    }
                    if (bottomSpotimCounter) {
                        const zeroComment = isFirmaApp
                            ? "היו הראשונים להגיב"
                            : "לכתבה זו לא התפרסמו תגובות, היו הראשונים להגיב";
                        const severalComments = `${res.data.messages_count[articleId]} תגובות`;
                        const text =
                            res.data.messages_count[articleId] === 0
                                ? zeroComment
                                : severalComments;
                        bottomSpotimCounter.innerText = text;
                    }
                    if (bottomSpotimMobileWebCounter) {
                        const zeroComment = "היו הראשונים להגיב";
                        const severalComments = `${res.data.messages_count[articleId]} תגובות`;
                        const text =
                            res.data.messages_count[articleId] === 0
                                ? zeroComment
                                : severalComments;
                        bottomSpotimMobileWebCounter.innerText = text;
                    }
                    if (bottomSpotimCommentsExist) {
                        const text = "לקריאת כל התגובות";
                        res.data.messages_count[articleId] !== 0
                            ? (bottomSpotimCommentsExist.innerText = text)
                            : bottomSpotimCommentsExist.remove();
                    }
                    if (siteVerticalArticleSocialShareWrapper) {
                        const numberSpan = document.querySelector(
                            "#SiteVerticalArticleSocialShare_Wrapper .verticalSidebarCommentNumber"
                        ) as HTMLElement;
                        if (numberSpan) {
                            numberSpan.innerText =
                                res.data.messages_count[articleId] === 0
                                    ? "0"
                                    : res.data.messages_count[articleId];
                        }
                    }
                    if (mutamHeaderCommentIcon) {
                        const numberSpan = document.querySelector(
                            "#commentButtonInHeader"
                        ) as HTMLElement;
                        if (numberSpan) {
                            const messageCount =
                                res.data.messages_count[articleId] || 0;
                            numberSpan.innerText =
                                messageCount === 0 ? "" : messageCount;
                        }
                    }
                })
                .catch((err) =>
                    console.error("error geting ticker data:", err)
                );
        };
        s.setAttribute("src", "https://launcher.spot.im/spot/sp_AJXaDckj");
        s.setAttribute("data-spotim-module", "spotim-launcher");
        s.setAttribute("data-post-url", window.location.href);
        s.setAttribute("data-post-id", articleId);
        var script = document.createElement("script");
        script.text = `window.enableSpotIm = true`;
        document.head.appendChild(script);
        const spotImtag = document.querySelector("#spot_im_desktop");
        const commentsPopUp = document.querySelector(".spotimWrapper");
        if (isPopUp) {
            s.setAttribute("data-spotim-autorun", "false");
            s.async = true;
            document.body.prepend(s);
            if (!isFirmaApp) {
                const conversationDiv = document.createElement("div");
                conversationDiv.setAttribute(
                    "data-spotim-module",
                    "conversation"
                );
                conversationDiv.setAttribute(
                    "data-post-id",
                    this.props.articleId
                );
                commentsPopUp.append(conversationDiv);
            }
        } else {
            s.setAttribute("data-messages-count", spotimTalkbackCount);
            spotImtag.insertBefore(s, spotImtag.nextSibling);
        }
    }
    private closePopUp = () => {
        document
            .querySelector(".spotimWrapper")
            .classList.replace("isOpened", "isClosed");
        enablePageRefresh();
    };

    render() {
        const { className, isPopUp, isFirmaApp } = this.props;
        if (isFirmaApp) {
            return null;
        } else
            return (
                <div
                    className={classNames(
                        className ? className : "spotimWrapper",
                        { popUp: isPopUp }
                    )}
                >
                    {isPopUp && (
                        <div className="closePopUp" onClick={this.closePopUp} />
                    )}
                    {!isPopUp && <div id="spot_im_desktop" />}
                </div>
            );
    }
}
