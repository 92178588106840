import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { StoriesAutoSlotItem } from "./storiesAutoSlotItem";
import { ISlot } from "./storiesAutoWrapper";
import Slider from 'react-slick'
import { StoriesItem } from "./storiesItem";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { Splide, SplideSlide } from '@splidejs/react-splide';

interface StoriesAutoComponentaProps {
    componentaId?: string
    fieldsDisplaySettings: IFieldDisplayData
    hideVideoAds?: boolean
    tabBackgroundColor?: string
    slots: ISlot[]
    storiesProgressTime: number
    tabTitle: string
}

interface StoriesAutoComponentaState {
    storyEntities: StoriesEntity[]
    pageNumber: number
    isStoriesDisplay: boolean
    activeEntityIndex: number
}

export interface StoriesEntity extends ISlot {
    linkedItems: ArticleExtraData[]
    slotId: string
}
export class StoriesAutoComponenta extends React.Component<StoriesAutoComponentaProps, StoriesAutoComponentaState> {
    public static siteScriptName = "StoriesAutoComponenta"
    constructor(props) {
        super(props)
        this.state = {
            storyEntities: [],
            pageNumber: 0,
            isStoriesDisplay: false,
            activeEntityIndex: 0,
        }
    }
    private storiesSlider;

    componentDidMount() {
        this.getArticles()
    }

    private getArticles = async () => {
        const { componentaId, slots } = this.props;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        try {
            const articleRequests = slots.map(async (item) => {
                const response = await axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/type/${item.contentSource.type}/id/${item.contentSource.link.id}`);
                // @ts-ignore
                const linkedItems = response.data.data as ArticleExtraData[];
                return {
                    ...item,
                    linkedItems,
                    slotId: item.slotId,
                };
            });

            const allArticles: StoriesEntity[] = await Promise.all(articleRequests);

            this.setState({ storyEntities: allArticles });
        } catch (error) {
            console.error("Error getting headline data:", error);
        }
    }

    private onStoriesOpen = (clickedIndex: number) => {
        this.setState({ isStoriesDisplay: true, activeEntityIndex: clickedIndex });
        disablePageRefresh();
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isStoriesDisplay: false });
        enablePageRefresh();
    }

    private onStorySelect = (index: number) => {
        this.storiesSlider.slickGoTo(index);
        // this.setState({ activeEntityIndex: index }/* ,() => this.storiesSlider.slickGoTo(this.state.activeEntityIndex) */);
    }

    private onStoryNext = () => {
        this.storiesSlider.slickPrev();
    }

    private onStoryPrev = () => {
        this.storiesSlider.slickNext();
    }

    public render() {
        const { fieldsDisplaySettings, tabTitle } = this.props;
        const { storyEntities, isStoriesDisplay, activeEntityIndex } = this.state;

        const desktopSettings = {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
        };

        // const StoriesMobileItemSettings = {
        //     slidesToScroll: 1,
        //     autoplay: false,
        //     swipe: false,
        //     className: "center",
        //     centerMode: true,
        //     infinite: false,
        //     centerPadding: "0px",
        //     slidesToShow: 5,
        //     speed: 800,
        //     initialSlide: activeEntityIndex,
        //     beforeChange: (oldIndex, newIndex) => {
        //         this.setState({ activeEntityIndex: newIndex },() => console.log(newIndex))
        //     },
        // }

        return (
            <div className="storiesAutoComponenta">
                {fieldsDisplaySettings.isTabVisible &&
                    <div className="storiesAutoComponentaTab componentaTab">
                        <div className="tabTitle">{tabTitle}</div>
                    </div>
                }
                {isStoriesDisplay && <div className="closeBtn" onClick={this.onCloseVideoGalleryView}></div>}
                <div className="galleryWrapper">
                    <Slider {...desktopSettings} className="slider"  >
                        {storyEntities.map((entity, index) => <StoriesAutoSlotItem
                            key={entity.slotId}
                            entity={entity}
                            onStoriesOpen={this.onStoriesOpen}
                            index={index} />)}
                    </Slider>
                </div>
                {isStoriesDisplay &&
                    <div className="storiesDisplayWrapper">
                        {/* <Slider {...StoriesMobileItemSettings} className="slider" ref={storiesSlider => (this.storiesSlider = storiesSlider)}>
                            {storyEntities.map((entity, index) =>
                                <StoriesItem
                                    key={entity.slotId}
                                    entity={entity}
                                    index={index}
                                    activeEntityIndex={activeEntityIndex}
                                    onStorySelect={this.onStorySelect}
                                    onStoryNext={this.onStoryNext}
                                    onStoryPrev={this.onStoryPrev}
                                />
                            )}
                        </Slider> */}

                        <Splide aria-label="My Favorite Images">
                            {storyEntities.map((entity,index) => (
                                <SplideSlide>
                                     <StoriesItem
                                    key={entity.slotId}
                                    entity={entity}
                                    index={index}
                                    activeEntityIndex={activeEntityIndex}
                                    onStorySelect={this.onStorySelect}
                                    onStoryNext={this.onStoryNext}
                                    onStoryPrev={this.onStoryPrev}
                                />
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                }
            </div>
        )
    }
}