import { ArticleExtraData } from "articlePage/stateInterfaces"
import { formatDate } from "../../../siteWidgets/siteWidgetUtils";
import { MusafArticleList } from "./musafArticleList";
import { MainMusafItem } from "./mainMusafItem"
import * as axios from "axios"

interface MusafArchiveProps {
    firstPageArticles: any
    componentaId: string
    numberOfVisibleItems: number
    titleText: string
}
interface MusafArchiveState {
    currPage: number
    articles: ArticleExtraData[]
    isLoadMore: boolean
}


export class SiteMusafArchiveComponenta extends React.Component<MusafArchiveProps, MusafArchiveState>  {
    public static siteScriptName = "SiteMusafArchiveComponenta"
    constructor(props) {
        super(props)
        this.state = {
            currPage: 0,
            articles: [],
            isLoadMore: props.firstPageArticles && (props.firstPageArticles.length / this.props.numberOfVisibleItems) >= 1,
        }
    }

    componentDidMount() {
        this.getArticles();
    }

    componentDidUpdate(prevProps: MusafArchiveProps, prevState: MusafArchiveState, prevContext: any): void {

        if (prevState.currPage !== this.state.currPage) {
            this.getArticles();
        }
        if (prevState.articles !== this.state.articles) {
            this.setState({
                isLoadMore: (this.state.articles.length) / ((this.state.currPage + 1) * this.props.numberOfVisibleItems) >= 1
            })
        }
    }



    private getArticles = () => {
        const { articles, currPage, } = this.state;
        const { componentaId, firstPageArticles } = this.props;
        const firstArticle = firstPageArticles[0];
        const startDate = new Date('2010-01-07');
        const limitDate = new Date(firstArticle.dateUpdatedOnSite);
        limitDate.setDate(limitDate.getDate() - 1);
        const formatedStartDate = formatDate(startDate, "YYYY-MM-DD");
        const formatedEndDate = formatDate(limitDate, "YYYY-MM-DD");
        axios.get(`/iphone/json/api/article_list/${componentaId}/startDate/${formatedStartDate}/endDate/${formatedEndDate}/pageNumber/${currPage}`).then((res: any) => {
            const recivedArticles = res.data.data;
            this.setState({
                articles: currPage > 0 ? [...articles, ...recivedArticles] : recivedArticles,
            })
        }).catch(err => console.error("error geting articles data:", err))
    }

    private loadMoreArticles = () => {
        this.setState({ currPage: this.state.currPage + 1 })
    }

    public render() {
        const { articles, isLoadMore, } = this.state
        const { titleText, firstPageArticles } = this.props
        const firstArticle = firstPageArticles[0];

        return (
            <div className="MusafArchiveComponenta">
                {articles.length > 0 &&
                    <MainMusafItem
                        article={firstArticle}
                        imageId={firstArticle.specialData}
                        publishedLink={firstArticle.publishedLink}
                    />
                }

                <div className="separator_musaf" />

                {articles.length > 1 &&
                    <div className="musafs">
                        <div className="componentTitle">{titleText}</div>
                        <MusafArticleList itemList={articles} />
                    </div>}
                {isLoadMore && <div className="loadMore">
                    <button className="loadMoreButton" disabled={!isLoadMore || articles.length === 0}
                        onClick={this.loadMoreArticles}>טען עוד</button>
                </div>}

            </div>
        )
    }

}