import * as filter from "lodash/filter"
import * as map from "lodash/map"
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { SiteArticleHeadlinesData } from "./articleHeadlinesItemCalcalist"
import { CalcalistArticleHeadlinesList } from "./calcalistArticleHeadlinesList";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"

interface InfinityHeadlinesViewProps {
    uid?: string
    articles: ArticleExtraData[]
    loader: boolean
    fieldsDisplaySettings: IFieldDisplayData
    titleColor: string
    subTitleColor: string
    isTaboolaVisible: boolean
    hideVideoAds: boolean
    commercialItemsNum:number
    commercialRepetitionsNum:number
    isMutam: boolean
}

export class CalcalistArticleHeadlinesView extends React.Component<InfinityHeadlinesViewProps, {}>  {

    private getArticlesFromPage = () => {
        const existingArticlesIds = new Set<String>()
        const { uid } = this.props
        if (typeof document !== 'undefined') {
            const elements = document.querySelectorAll(`div[data-wcm-article-id]:not([read-more-id="${uid}"])`)
            if (elements.length) {
                for (let i = 0; i < elements.length; i++) {
                    const el = elements[i];
                    const articleId = el.getAttribute("data-wcm-article-id")
                    if (articleId) {
                        existingArticlesIds.add(articleId)
                    }
                }
            }
        }
        return existingArticlesIds
    }

    public render() {
        const { articles, uid } = this.props
        let itemList: SiteArticleHeadlinesData[] = [];

        if (articles) {
            const existingArticles = this.getArticlesFromPage()
            if (Object.keys(articles).length > 0) {
                itemList = filter(map(articles, (article: ArticleExtraData): SiteArticleHeadlinesData =>
                    existingArticles.has(article.articleId) ? null : ({
                        articleId: article.articleId,
                        author: article.articleType ==="audio" && article.specialData && article.specialData.audioData && article.specialData.audioData.alternatePresenterName.length > 0 ? article.specialData.audioData.alternatePresenterName : article.author,
                        credit: article.promotionImageDetails ? article.promotionImageDetails.credit : null,
                        roof: article.roof ? article.roof : null,
                        title: article.title,
                        subTitle: article.subTitle,
                        promotionImageDetails: article.promotionImageDetails,
                        hasVideo: article.hasVideo,
                        publishedLink: article.publishedLink,
                        publishedDate: article.dateUpdatedOnSite,
                        video: article.video,
                        articleType:article.articleType,
                    })
                ))
            }
        }

        return (
            <CalcalistArticleHeadlinesList
                itemList={itemList}
                {...this.props}
                uid={uid}
            />
        )
    }
} 