import { SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import * as apiUrls from "config/apiUrls"
import { ArticleExtraData } from "../../../../articlePage/stateInterfaces";
import { getImgAlt, isAriaHidden, separateHourFromDateAndFormatToString } from "../../../widgetsUtils";

interface MainMusafItemProps {
    article: Partial<ArticleExtraData>
    publishedLink: string
    imageId: string
}

export class MainMusafItem extends React.Component<MainMusafItemProps, {}>{
    public render() {
        const { article: { promotionImageDetails, publishedLink, dateUpdatedOnSite, specialData } } = this.props;
        let title = `שער מוסף ${separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}`

        return (
            <div className="MainMusafItem" >
                <div className="rightArea" >
                    <div className="title">
                        מוסף
                        <br />
                        כלכליסט
                    </div>
                    <div className="dateView">
                        {separateHourFromDateAndFormatToString(dateUpdatedOnSite, { showDate: true, showHour: false })}
                    </div>
                </div>
                <div className="mediaArea">
                    <div className="MediaCarousel" >
                        <SimpleLinkRenderer href={publishedLink} target="_blank">
                            <img src={apiUrls.getImageUrl(promotionImageDetails, { w: 566, h: 766 })}
                                style={{ width: 566, height: 766 }}
                                alt={getImgAlt(promotionImageDetails)}
                                aria-hidden={isAriaHidden(promotionImageDetails)}
                                title={title}
                            />
                        </SimpleLinkRenderer>
                    </div>
                </div>
            </div>

        )
    }
}