import { RenderReactAtSite } from "../../../../commonComponents/inline/renderReactAtSite";
import { ArticleData } from "../../../../../articlePage/stateInterfaces";
import { ArticleMobileHeaderShareComponent } from "../../componentas/articleHeaderComponenta/articleMobileHeaderShareComopnent";

interface ArticleMagazineTopStoryHeaderProps {
    data: ArticleData
    domain: string
}

export default class ArticleMagazineMobileShareWrapper extends React.Component<ArticleMagazineTopStoryHeaderProps, {}> {
    public static siteScriptName = "ArticleMagazineMobileShareWrapper";

    render() {
        const { data, domain } = this.props
        return (
            <RenderReactAtSite>
                <ArticleMobileHeaderShareComponent
                    url={data.publishedLink}
                    title={data.title}
                    showComments={data.showComments}
                    lang={"he"} 
                    domain={domain} 
                    isSpotim={false}
                />
            </RenderReactAtSite>
        )
    }
}
